@import "styles/theme.scss";

.wizard-body {
  .wizard-main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: 72px;

    .wizard-header {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
      height: 64px;
      width: 100%;
      z-index: 200;
      background: #fff;
    }

    .page-header {
      margin: 65px 0 0;
      padding: 20px 20px 0;
      .page-title-conatiner {
        display: flex;
        flex-direction: column;
        padding-right: 10px;

        .page-title {
          font-size: $large-text-font-size;

          .page-title-info {
            background: $background-color;
            border-radius: 32px;
            padding: 0.2em 1em;
            font-size: $label-font-size;
            margin-left: 1em;
            font-weight: 500;
            vertical-align: middle;
          }

          @include rwd-min(1024) {
            font-size: $large-text-font-size;
          }
        }

        .page-label {
          font-size: 13px;
          color: $primary-color-text;
          font-weight: 400;
          max-width: 470px;
        }
      }

      .button-wrapper {
        min-width: 180px;
      }
      
      .grey-btn {
        background-color: #E7E9ED!important;
        .anticon {
          width:24px;
        }
      }

      @media (max-width: 568px) {
        text-align: center;
        flex-direction: column;
        .page-title {
          margin-bottom: 16px;
        }
        .color-divider {
          position: absolute;
          top: 57px;
          left: calc(50% - 16px);
        }
        .button-wrapper {
          margin: 10px auto;
        }
      }

      @media (min-width: 1200px) {
        .button-wrapper {
          display: none;
        }
      }
    }

    .site-page-header {
      padding: 0;

      .ant-page-header-heading>.ant-page-header-heading-left>span {
        font-size: $title-font-size;
      }

      .site-page-header-divider {
        min-width: 0;
        width: 3em;
        border-top: 1px solid $secondary-color;
        margin: 0;
      }
    }

    .wizard-content {
      position: relative;
      display: flex;
      flex-direction: column;

      .site-layout-background {
        background: transparent;

        .ant-layout-content {
          display: flex;
          flex-direction: column;
          position: relative;

          .wizard-content-container {
            @include custom-scrollbar;
            overflow-y: auto;
            overflow-x: hidden;
            flex: 1;
            padding-bottom: 0!important;

            .wizard-loader {
              position: relative;
            }

            .wizard-inner-content {
              padding: 0 20px 20px;
              width: 100%;

              label {
                color: $label-color-light;
              }

              >.input-container {
                width: 100%;
                margin-bottom: 1.2em;
                &.icon {
                  display: flex;
                  flex-wrap: wrap;
                  gap: 8px;
                }
              }

              @include rwd-min(1440) {
                padding: 2em;
                width: 75em;
              }
            }

            .project-description {
              height: 5.5em;
            }

            .project-goals {
              height: 5em;
            }

            &.wizard-language-rtl {
              .wizard-inner-content {

                .input-container,
                .wix-client-name-dropdown-container {
                  .ant-select-selection-placeholder,
                  .input-sub-label {
                    text-align: right;
                  }

                  input,
                  textarea,
                  .ant-row {
                    direction: rtl;
                  }

                  #wix-client-name-dropdown-button {
                    .ant-col {
                      img {
                        margin-right: 0;
                        margin-left: 0.5em;
                      }
                    }
                  }
                }
              }

              .tablist-container {
                position: relative;

                .tablist-prelist-container {
                  .tablist-prelist {
                    .tablist-prelist-input {
                      .input-container {

                        input,
                        .ant-input-affix-wrapper {
                          direction: rtl;
                        }
                      }
                    }

                    .tablist-prelist-content {
                      .ant-list-item {
                        direction: rtl;
                        text-align: right;

                        .ant-list-item-meta-title {
                          img {
                            margin-right: 0;
                            margin-left: 0.6em;
                          }
                        }
                      }
                    }
                  }
                }

                .tablist-content {
                  .tablist-sortlist-container {
                    .tablist-sortlist-content {
                      .tablist-input-container {

                        .input-container,
                        .select-container {
                          .ant-row {
                            .input-label {
                              direction: rtl;
                              text-align: right;
                            }
                          }

                          input,
                          textarea,
                          .ant-select-selection-placeholder {
                            direction: rtl;
                          }

                          .ant-select-item {
                            direction: rtl;
                            text-align: right;
                          }

                          &.super-textarea textarea {
                            padding-right: 0 !important;
                          }
                        }
                      }

                      .tablist-input-description {
                        direction: rtl;
                        text-align: left;
                      }
                    }
                  }

                  .sortlist-price-fields {
                    .input-container {
                      direction: rtl;
                      max-width: 150px;
                    }
                  }
                }
              }

              .tablist-input-container {
                .input-container {
                  direction: rtl;
                }
              }
            }
          }
        }
      }
    }


    .wizard-content-options {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 15px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
      background: $white-color;

      .more-buttons {
        display: none;
      }

      .main-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 15px;
      }

      .undo-redo {
        padding: 0 25px;
        margin: auto 0;
        justify-content: space-between;
        display: flex;
        width: 7.5em;
      }

      .wizard-quit {
        min-width: 110px;
      }

      @include md() {
        .step-4 {
          .wizard-quit {
            min-width: auto;
          }
        }
      }

      .create-proposal {
        margin: 0 !important;
        margin-right: 3px !important;
      }

      .anticon-check {
        background: rgba(255, 255, 255, 0.2);
        height: 28px;
        width: 28px;
        border-radius: 4px;
        @extend .flex-center;
      }
    }


    .sidebar-wizard,
    .sidebar-wizard-collapsed {
      padding: 2em;
      box-shadow: $box-shadow;
      border-radius: 0px 6px 6px 0px;
      background-color: $primary-color;
      overflow: hidden;
      position: relative;

      .ant-layout-sider-children {
        @include custom-scrollbar;
        overflow-y: auto;
        position: absolute;

        .ant-steps-item {
          height: 80px;

          >.ant-steps-item-container {
            .ant-steps-item-tail {
              top: -34px;
              height: 190%;
              left: 19px;

              &::after {
                background-color: $primary-color-light;
              }
            }

            .ant-steps-item-content {
              &>.ant-steps-item-title {
                color: $white-color;
                font-size: $label-font-size;
                font-weight: 300;
                letter-spacing: 0.5px;
              }

              @include sm {
                overflow: visible;

                .ant-steps-item-title {
                  font-size: 9px;
                }
              }
            }

            .ant-steps-item-icon {
              margin: 0px 8px 0px 4px;
              background-color: $primary-color-light;

              .ant-steps-icon {
                color: $white-color !important;
              }
            }

            .ant-steps-item-tail::after {
              width: 2px;
            }

            &:hover .ant-steps-item-icon {
              color: $white-color !important;
              border-color: $white-color !important;
            }
          }
        }

        .ant-steps-item-active {
          height: 110px;

          .ant-steps-item-container {
            .ant-steps-item-content>.ant-steps-item-title {
              font-weight: 600;
            }

            .ant-steps-item-tail::after {
              background-color: $white-color;
              height: 90%;
            }

            .ant-steps-item-icon {
              background-color: $white-color;
              width: 40px;
              margin: 0px 8px 0px 0px;
              height: 40px;

              .ant-steps-icon {
                color: $primary-color-text !important;
                vertical-align: sub;
                font-weight: 700;
              }
            }
          }
        }

        .ant-steps-item-finish>.ant-steps-item-container {
          .ant-steps-item-icon {
            background-color: $completed-color;
          }

          .ant-steps-item-tail::after {
            background-color: $completed-color;
          }
        }
      }
    }

    .sidebar-wizard-collapsed {
      padding: 2em 0.5em;
    }
  }

  .sidebar {
    display: none;
  }

  label {
    .asterisk {
      color: $form-error-color;
    }
  }

  .input-container {
    &.error {
      &.autocomplete-wrapper {
        .ant-select-selector {
          border-color: $form-error-color!important;
        }

        .ant-select-focused .ant-select-selector {
          box-shadow: $error-box-shadow;
        }
      }

      &>.ant-input {
        border-color: $form-error-color;

        &:focus {
          box-shadow: $error-box-shadow;
        }
      }
    }
  }
  
  .ant-tabs-tabpane {
    .tablist-active-tab {
      // width of price table in wizard when there's custom column
      &.tablist-custom {
        min-width: calc(800px + var(--custom-columns) * 150px) !important;
      }

      &.tablist-quantity {
        .quantity,
        .items,
        .price,
        .price-options {
          width: 120px;
        }
      }
    }
  }

  @include rwd-min(992) {
    .wizard-main {
      padding: 0 40px 20px;

      .wizard-header {
        .proposal-header {
          .undo-redo-buttons {
            display: none;
          }
        }
      }

      .page-header {
        padding: 0;
        margin: 85px 0 20px;
      }

      .wizard-content {
        position: relative;
        background-color: #fff;
        border-radius: 6px;
        overflow: hidden;
        min-height: 500px;
        height: calc(100vh - 220px);
      }

      .wizard-inner-content {
        padding: 20px !important;
        max-width: 700px;
      }

      .wizard-content-options {
        position: absolute;
        padding: 20px !important;

        .more-buttons {
          display: flex;
        }

        .main-buttons {
          width: auto;
          justify-content: flex-end;
          gap: 20px;
        }

        .ant-btn-lg {
          min-width: 150px;
        }
      }
    }
  }

  @include rwd-min(1200) {
    .wizard-main {
      .wizard-content-options {
        position: relative;
        z-index: 5;
      }
    }
  }

  .wizard-inner-content {
    .ant-select,
    .ant-select input,
    .ant-select-selector,
    .ant-input {
      min-height: 40px;
      border-radius: 4px !important;
    }
  }
}

.ant-tooltip.wizard-tooltip-email {
  width: auto;
  max-width: 100%;
  font-size: 12px;
  &.settings-tip {
    max-width: 20%;
  }

  .ant-tooltip-inner {
    color: $primary-color-text !important;
    text-align: center;
  }
}


.ant-select-dropdown {
  .wizard-language-rtl {
    input,
    .ant-select-item {
      direction: rtl;
      text-align: right;
    }
  }
}
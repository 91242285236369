@import 'styles/theme.scss';

.email-recipients-popover {
    .popover-wrapper {
        padding: 16px;
        @media (min-width: 768px) {
            max-width: 581px;
            min-width: 398px;
        }
    }
}

.add-recipient-form {
    .ant-form-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0!important;

        .form-label {
            color: $label-color-light;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            opacity: 0.8;
        }

        input {
            border-radius: 4px;
            padding: 12px;
            border: 1px solid $tab-border;
            background: $white-color;
            color: $primary-color-text;
            font-size: 14px;
            font-weight: 400;
            height: 40px;
        }
    }

    .ant-form-item-control {
        width: 100%;
    }

    .ant-form-item-explain-error {
        font-size: 12px;
    }

    .ant-form-item-label>label {
        height: auto;
        margin-bottom: 4px;
    }

    .footer {
        gap: 12px;
        margin-top: 20px;
    }
}

.edit-recipient-form {
    .ant-form-item {
        margin: 0;
        flex-wrap: nowrap!important;
    }
    .ant-form-item-label{
        flex: none!important;
    }

    .delete-icon {
        height: 40px;
        width: 40px;
        @extend .flex-center;
        svg {
            height: 20px;
            width: 20px;
        }
    }

    .email-input {
        flex: 1;
    }
    .email-col {
        display: flex;
        align-items: flex-end;
    }
}
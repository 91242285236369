@import 'styles/theme.scss';

.font-menu {
  background-color: $white-color !important;
  padding: 0 var(--section-padding);
  margin-bottom: 100px;
  .header-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $primary-color-text;
  }
  .ant-col .ant-form-item-label .ant-form-item-no-colon {
    font-weight: normal;
    font-size: $font-size-base;
    line-height: 140%;
    color: $label-color-light;
  }

  .section-divider {
    width: calc(100% + 2 * var(--section-padding));
    margin-left: calc(var(--section-padding) * -1);
  }
}

@import "styles/theme.scss";


.layout-menu, .section-spacing {
  background-color: $white-color !important;
  padding: 0 var(--section-padding);
  margin-bottom: 100px;

  .slider-row {
    padding: 1em;
    background-color: #F5F6FA;
    border-radius: 4px;

    .custom-spacing-slider {
      margin-right: 1em;
      .ant-slider-handle {
        width: 18px;
        height: 18px;
        margin-top: -7px;
        border: none;
        box-shadow: 0px 3px 4px -3px #a3a3a3;
      }
      .ant-slider-step, .ant-slider-rail, .ant-slider-track {
        height: 7px;
        border-radius: 7px;
      }
      .ant-slider-track {
        background-color: #2F2C88;
      }
      .ant-slider-rail {
        background-color: #CBCED8;
      }
    }

    .custom-spacing-input {
      width: 100%;
      border: 1.5px solid #E2E3E8;
      border-radius: 6px;

      .ant-input-number-handler-wrap {
        background: transparent;
        border-left: none;
        opacity: 1 !important;
        pointer-events: none;

        &::before {
          content: 'px';
          position: absolute;
          right: 0;
          top: 4px;
          color: #C7CAD6;
          padding: 0 0.5em;
        }

        span {
          display: none;
        }
      }
    }
  }

  .ant-divider {
    border-top-color: $tab-border;

    &.ant-divider-horizontal.ant-divider-with-text-left::before {
      width: 0%;
    }

    .ant-divider-inner-text {
      padding: 0 0.5em 0 0;
      text-transform: capitalize;

      h5 {
        font-weight: 600;
        font-size: $sub-font-size;
        letter-spacing: 0.04em;
        color: $label-color-light;
        margin-top: 0em;
      }
    }
  }

  .layout-menu-input-wrapper {
    .settings-label {
      display: flex;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: $label-color-light;
      width: 100%;

      span {
        text-transform: capitalize;
      }

      &>span:first-child {
        flex-grow: 1;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-radio-wrapper {
    padding: 4.8px;
    border-radius: 4px;
    min-width: 85px;
    width: 100%;
    font-size: 12.2px !important;
    color: $primary-color-text !important;
    border: 1px solid $tab-border;
    text-overflow: ellipsis !important;
    overflow-x: hidden !important;

    .ant-radio-checked {
      &:after {
        border: none;
      }
      .ant-radio-inner {
        &:after {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiByeD0iOCIgZmlsbD0iIzA1MDM0RCIvPjxwYXRoIGQ9Ik00IDguNUw3IDExbDUtNiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+);
            background-size: cover;
            background-color: initial !important;
            position: absolute;
            top: 50%;
            left: -1px;
            transform: translateY(-50%);
        }
      }
    }

    &.ant-radio-wrapper-checked {
      border: 1px solid $primary-color-light;
    }
  }

  .reset-item {
    margin-top: 30px;
  }
}
// ---------- common code for buttons ----------
.ant-btn {


  // ---------- small and medium buttons ----------
  &.button,
  &.small-btn,
  &.medium-btn,
  &.large-btn {
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: unset !important;
    box-shadow: unset !important;
    height: 24px;
    padding: 0 6px;
    font-weight: 500;
    gap: 4px;

    .anticon+span {
      margin-left: unset;
    }
    span + .anticon {
      margin-left: unset;
    }

    >span {
      display: flex;
      align-items: center;
      height: 24px;
      transition: 0.2s;
    }

    &.icon-only {
      gap: 0;

      .anticon {
        margin: 0;
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    &:after {
      display: none;
    }
  }

  // ---------- medium sized button ----------
  &.medium-btn {
    height: 32px;
    padding: 0 14px;
    gap: 8px;
  }

  // ---------- large sized button ----------
  &.button,
  &.large-btn {
    height: 42px;
    padding: 0 18px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    gap: 14px;

    &.icon-only {
      padding: 0 14px;
    }
  }


  // ---------- colored button ----------
  &.ant-btn-primary,
  &.confirm,
  &.secondary-btn {
    background-color: $secondary-color !important;
    color: $white-color !important;
    box-shadow: 0 10px 15px -4px rgba(241, 92, 60, 0.4) !important;
    & svg>* {
      fill: $white-color;
    }

    &:hover {
      filter: brightness(1.1);
      color: $white-color !important;
    }
  }

  &.primary-btn {
    background-color: $link-color !important;
    color: $white-color !important;
    &:not(:disabled):hover {
      filter: brightness(1.1);
    }
    &:disabled {
      background-color: $light-color!important;
      color: $link-color !important;
      opacity: 0.6;
    }
  }

  &.cancel,
  &.grey-btn {
    background-color: #F5F6FA !important;
    color: $primary-color-text !important;

    &:hover {
      filter: brightness(0.95);
    }
  }

  &.white-btn {
    background-color: $white-color !important;
    color: $primary-color-text !important;
  }

  &.transparent-btn {
    background-color: transparent !important;
    color: $primary-color !important;
    &.hover:hover {
      filter: unset;
      background-color: $transform-color !important;
    }
  }

  &.blue {
    background-color: $section-color !important;
    color: $white-color !important;

    &:hover {
      filter: brightness(1.2);
    }
  }

  &.light-blue {
    background-color: $publish-background !important;
    color: $section-color !important;

    & svg>* {
      fill: $section-color;
    }

    &:hover {
      filter: brightness(0.95);
    }
  }

  &.grey-line-btn {
    background-color: transparent !important;
    border: 1px solid $border-color-base !important;
    color: $label-color-dark !important;
  }

  &.primary-line-btn {
    background-color: transparent !important;
    border: 1px solid $link-color !important;
    color: $link-color !important;
  }

  &.green-line-btn {
    background-color: transparent !important;
    border: 1px solid $completed-color !important;
    color: $completed-color !important;
  }

  &.icon-right {
    flex-direction: row-reverse;
    padding-right: 12px;
  }
}


/*------------ show / hide as per device width ---------------*/
@media (max-width: 575.9px) {
  // display something only in screen > sm, i.e > 576
  .above-sm {
    display: none !important;
  }
}

@media (min-width: #{$screen-sm-min}) {
  // display something only in screen < sm, i.e > 576
  .below-sm {
    display: none !important;
  }
}


@media (max-width: 766.9px) {
  .above-md {
    display: none !important;
  }
}

@media (min-width: #{$screen-md-min}) {
  .below-md {
    display: none !important;
  }
}


@media (max-width: 991.9px) {
  .above-lg {
    display: none !important;
  }
}

@media (min-width: #{$screen-lg-min}) {
  .below-lg {
    display: none !important;
  }
}

@media (max-width: 1199.9px) {
  .above-xl {
    display: none !important;
  }
}

@media (min-width: #{$screen-xl-min}) {
  .below-xl {
    display: none !important;
  }
}
/*------------ END of show / hide as per device width ---------------*/



/*------------ hide 2nd month for date range picker in mobile ---------------*/
@media (max-width: 600px) {
  .single-month-picker {
    .ant-picker-panels {
      .ant-picker-panel:nth-child(2) {
        .ant-picker-date-panel {
          width: 0;

          .ant-picker-header {
            position: absolute;
            right: 30px;
          }
        }

        .ant-picker-header-prev-btn,
        .ant-picker-header-super-prev-btn,
        .ant-picker-header-view,
        .ant-picker-body {
          display: none;
        }
      }
    }
  }
}
/*------------ END hide 2nd month for date range picker in mobile ---------------*/
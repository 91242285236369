@import "styles/theme.scss";

.draft-editor .section-wrapper {
  position: relative;
  .section-action-buttons-wrapper,
  .section-action-buttons,
  .section-order-buttons,
  .new-section-container {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s;
    position: absolute;
    &.top {
      top: -12px;
    }
    &.bottom {
      bottom: -12px;
    }
  }

  .new-section-container {
    left: 50%;
    transform: translateX(-50%);
    .ant-btn {
      display: flex;
      align-items: center;
      padding-left: 2px;
      transition: transform 0.4s;
      border: none;
      box-shadow: none;
      &:hover {
        color: white;
        transform: scale(1.3);
      }
      .anticon {
        display: flex;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  transition: border-color 0.4s;
  border: 2px solid transparent;

  .section-order-buttons {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 3;
    background-color: $white-color;
    font-size: $label-font-size;
    font-weight: normal;
    line-height: 140%;
    text-align: center;
    color: $primary-color-text;
    display: flex;
    box-shadow: 0px 2px 4px rgba(0, 38, 95, 0.2);
    border-radius: 4px;

    & > span {
      cursor: pointer;
      padding: 4px 8px;
      display: inline-block;
      height: 26px;
      vertical-align: middle;
      &:not(:last-child) {
        border-right: 1px solid $border-color-tab;
      }
      &:hover {
        background-color: $light-color;
      }
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
        span.delete-icon {
          height: auto;
          padding: 0;
        }
      }
    }
  }

  .new-section-button {
    height: 24px;
    background: $section-color;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: white;
    border-radius: 44px;
    &:hover {
      background: $section-color;
      border-color: $section-color;
    }
  }

  .section-action-buttons-wrapper {
    left: 8px;
    top: 8px;
    z-index: 13 !important;
    @media (max-width: 599.9px) {
      position: relative;
      visibility: visible;
      opacity: 1;
      .section-action-buttons {
        position: absolute;
        top: 46px;
        left: 0;
      }
    }

    .settings-btn {
      padding: 0 11px;
      margin-bottom: 10px;
  
      .anticon {
        width: 24px;
        transition: 0.2s;
      }
  
      .cross-icon {
        position: absolute;
      }
    }
  
    &.active .control-icon,
    &.inactive .cross-icon {
      opacity: 0;
      transform: rotate(45deg) scale(0);
    }


    .section-action-buttons {
      display: flex;
    }

    .group {
      display: flex;
      background-color: $white-color;
      color: $primary-color-text;
      box-shadow: $box-shadow;
      border-radius: 4px;
      text-align: center;
      font-size: 0.9em;
      margin-right: 4px;
      height: 26px;

      & > span {
        cursor: pointer;
        padding: 4px 8px;
        display: inline-block;
        height: 26px;
        vertical-align: middle;
        white-space: nowrap;

        &:not(:last-of-type) {
          border-right: 0.8px solid $border-color-tab;
        }
        &:hover {
          background-color: $light-color;
        }
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
          span.delete-icon {
            height: auto;
            padding: 0;
          }
        }
      }

      .dropdown {
        padding: 2px;
        line-height: 22px;
        &:hover {
          background-color: unset;
        }
        & > span {
          padding: 2px 8px;
          display: flex;
          align-items: center;
          border-radius: 3px;
          height: 22px;
          &.active,
          &:hover {
            background-color: $light-color;
            color: $section-color;
          }
        }

        &.active > span {
          background-color: $light-color;
          color: $section-color;
        }

        .anticon {
          height: 13px;
          width: 11px;
          margin-left: 6px;
        }
        svg * {
          fill: currentColor !important;
        }
      }
    }

    .section-action-column-options,
    .section-action-add-to-library {
      position: absolute;
      top: 2.2em;
      box-shadow: 0px 2px 4px rgba(0, 38, 95, 0.2);
      border-radius: 4px;
      background: white;
      padding: 6px;
      text-align: initial;
    }

    .section-action-add-to-library {
      .anticon {
        margin: 5px;
        cursor: pointer;
        &:hover {
          svg rect:first-child {
            fill: #c7bce4;
          }
        }
      }
    }

    .section-action-column-options {
      width: 300px;
      padding: 12px;
      .title {
        margin: 0 0 0 5px;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
      }
      .column-item-wrapper {
        width: 33.33%;
        padding: 5px;
        .column-item {
          position: relative;
          background: linear-gradient(180deg, #ffffff 0%, $tab-background 100%);
          border: 1px solid $tab-background;
          padding: 8px 4px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-size: 0.9em;
          cursor: pointer;
          &:hover {
            border-color: $svg-color-dark;
            svg {
              opacity: 0.7;
            }
          }
        }

        &.disabled{
          filter: saturate(0);
          cursor: not-allowed;
          svg {
            opacity: 0.4;
          }
          .column-item {
            pointer-events: none;
          }
        }

        &.active{
          .column-item {
            border-color: $svg-color-dark;
          }
        }

        .circle-tick-icon {
          position: absolute;
          top: 3px;
          right: 3px;
        }
      }

      @media screen and (min-width:768px) {
        width: 400px;
      }
    }
    .column-options-group {
      display: flex;
      flex-wrap: wrap;
      &.layout {
        margin-bottom: 10px;
        .anticon:not(.circle-tick-icon) {
          width: 40px;
          height: 32px;
          margin-bottom: 6px;
          border-radius: 4px;
          box-shadow: 0px 2px 4px 0px #b9b8d633;
          background: linear-gradient(180deg, #ffffff 0%, #f5f6fa 100%);
          svg {
            width: 100%;
          }
        }
      }

      &.column {
        .column-item {
          padding: 16px;
        }
      }
      &.section-spacing {
        margin-top: 0.7em;
        margin-bottom: 0;
        form {
          padding: 10px 5px;
          padding-top: 8px;
        }
      }
    }

    .section-action-add-to-library {
      padding: 1em;
      width: 100%;

      .section-action-add-to-library-header {
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.04em;
        color: $label-color-light;
      }

      .section-action-add-to-library-input {
        margin: 0.5em 0;
        .ant-form {
          width: 100%;
          .ant-form-item {
            margin-bottom: 0.5em;
          }
        }
        input {
          background: #ffffff;
          border: 1px solid $primary-color;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }

      .section-action-add-to-library-list {
        max-height: 11.5em;
        overflow-y: auto;

        .split-d1 {
          width: 100%;
          margin-bottom: 1em;
          .ant-tree-treenode {
            .anticon-file {
              display: none;
            }
          }

          .ant-tree-title {
            margin-left: 0.5em;
            .content-library-tree-title .anticon {
              display: none;
            }
          }

          button {
            display: none;
          }
        }
      }

      .section-action-add-to-library-button {
        background: $light-color;
        border-radius: 4px;
        border: none;
        width: 100%;
        margin: 0.5em 0;
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 140%;
          text-align: center;
          color: $primary-color-text;
        }
        .ant-btn-loading-icon{
          margin-right:5px;
        }
        .anticon-loading{
          width: 16px;
          height: 16px;
          margin:0;
          svg{
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    @media (max-width: 599.9px) {
      &.inactive {
        .section-action-buttons {
          display: none;
        }
      }
    }

    @media (min-width: 600px) {
      .settings-btn {
        display: none;
      }
    }
  }

  #section-columns-overlay {
    height: 100%;
    position: absolute;
    right: 0;
    width: 65%;
    z-index: 2;
    pointer-events: none;

    &.large {
      width: 79.8%;
    }
    &.top,
    &.top-middle,
    &.none {
      width: 94.5%;
      .ant-row {
        width: 94.5%;
      }
    }
    .ant-row {
      width: 93%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .ant-col {
        height: 100%;
        background: rgba(0, 90, 255, 0.1);
        border-left: 2px dashed $section-color;
        border-right: 2px dashed $section-color;
      }
    }
    .section-columns-overlay-one {
      background: rgba(0, 90, 255, 0.1);
      border-left: 2px dashed $section-color;
      border-right: 2px dashed $section-color;
    }
    .section-columns-overlay-two {
      .ant-col {
        width: 49%;
      }
    }
    .section-columns-overlay-three {
      .ant-col {
        width: 32.5%;
      }
    }
    .section-columns-overlay-four {
      .ant-col {
        width: 24.5%;
      }
    }
    .section-columns-overlay-two-left {
      .ant-col:first-child {
        width: 24%;
      }
      .ant-col {
        width: 74%;
      }
    }
    .section-columns-overlay-two-right {
      .ant-col:first-child {
        width: 74%;
      }
      .ant-col {
        width: 24%;
      }
    }
  }

  &:hover {
    border: 2px solid $section-color;
    .section-action-buttons-wrapper,
    .section-action-buttons,
    .section-order-buttons,
    .new-section-container {
      opacity: 1;
      visibility: visible;
      z-index: 1;
    }
  }
}

.new-section {
  padding: 30px;
  border: 2px dashed $section-color;
  background: $section-background;
  position: relative;
  direction:initial;
  .justify-content-center {
    justify-content: center;
  }
  .wrapper {
    max-width: 500px;
    margin: auto;
  }
  .justify-content-center {
    justify-content: center;
  }
  h4 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    color: $primary-color-dark;
  }
  h5 {
    margin-top: 20px;
    font-weight: 600;
    color: $primary-color-dark;
  }
  hr {
    opacity: 0.4;
  }

  .new-section-cancel-btn {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.4s;
    background: white;
    height: 24px;
    border-radius: 12px;
    font-size: 12px;
    border: 1px solid $section-color;
    color: $section-color;
    padding-left: 4px;
    span.anticon {
      display: flex;
      align-items: center;
    }
  }

  &:hover .new-section-cancel-btn {
    opacity: 1;
    z-index: 3;
  }

  .ant-col {
    margin-bottom: 16px;
  }
  .ant-card {
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: 65px;
    border: 1px solid $tab-background;
    background-image: linear-gradient(180deg, #ffffff 0%, #f5f6fa 100%);
    box-shadow: 0px 5px 10px rgba(3, 20, 63, 0.15);
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      border-color: $svg-color-dark;
    }
    .ant-card-body {
      width: 100%;
      display: flex;
      padding: 10px;
      img {
        margin: 4px;
      }

      .add-section-text {
        display: block;
        flex-grow: 1;
        text-align: left;
        align-self: flex-end;
        color: $primary-color-text;
        font-size: 13px;
        font-weight: normal;
        line-height: 140%;
        &.add-section-text {
          text-align: right !important;
          margin: auto;
        }
      }
    }
    .anticon {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      box-shadow: 0px 2px 4px 0px #b9b8d633;
      background: linear-gradient(180deg, #ffffff 0%, #f5f6fa 100%);
      svg {
        width: 100%;
      }
    }
  }
}
.new-section-drop{
  max-width: 270px;
  margin: auto;
}

.language-rtl {
  #section-columns-overlay {
    right: unset;
    left: 8px;
  }
  .section-action-column-options {
    right: 4px;
  }


  .section-wrapper {
    .section-action-buttons,
    .section-action-buttons-wrapper {
      left: unset;
      right: 8px;
    }
  }
}

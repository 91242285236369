@import 'styles/theme.scss';
.confirm-modal {
  width: 38em;
  .ant-modal-body {
    padding: 2em 3em;

    .logo {
      margin: 0 auto;
      width: 64px;
      display: block;
    }

    .divider {
      margin: 0 auto;
      margin-top: 1em;
      background: $secondary-color;
      width: 32px;
      height: 1px;
    }
    .title {
      display: flex;
      justify-content: center;
      margin-top: 1em;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: $primary-color-text;
    }
    .body {
      text-align: center;
      font-weight: normal;
      margin-top: 1.5em;
      margin-bottom: 3em;
      font-size: 14px;
      line-height: 150%;
      color: $primary-color-text;

      input {
        color: $primary-color-text;
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: space-around;
      margin-top: 2em;
      gap: 20px!important;
    }

    .large-btn {
      flex: 1;
      max-width: 147px;
      .ant-btn-loading-icon {
        position: absolute;
        left: 20px;
    
        & + span{
          padding-left: 20px;
        }
      }
    }
  }
}

.anticon.close-icon {
  &:hover {
    background: $light-color;
    border-radius: 50%;
    padding: 4px 4px;
  }
}

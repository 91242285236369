@import 'styles/theme.scss';

.library-modal {
  --modal-gap: 0;

  .ant-modal {
    width: calc(100% - 2 * var(--modal-gap)) !important;
    max-width: 1200px;
    top: 0;
    padding: 0;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 0px);
  }

  .ant-modal-body {
    padding: 0;
    border-top: 1px solid #f0f0f0 !important;
    overflow-y: auto;
  }

  .library-modal-container {
    height: 100%;
    display: flex;

    &.item-copying {
      justify-content: center;
      align-items: center;
    }

    .nav-section {
      width: 50%;
      height: 100%;
      overflow-x: auto;

      &>div {
        .nav-section-col {
          padding: 0 10px;
          width: 100%;
          overflow-y: auto;
        }
      }
    }

    img.item-img {
      width: 100%;
      aspect-ratio: 12/9;
      height: auto;
      filter: drop-shadow(0px 2px 4px rgba(3, 20, 63, 0.2));
      border-radius: 4px;
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width:768px) {
    .ant-modal {
      top: 0;
      max-width: 100% !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
    }

    .screen-1 {
      .nav-section-col:nth-child(2) {
        display: none;
      }
    }

    .screen-2 {
      .nav-section-col:nth-child(1) {
        display: none;
      }
    }
  }

  @media (min-width:768px) {
    .nav-section {
      width: 50%;

      &>div {
        display: flex;
        height: 100%;

        .nav-section-col {
          width: 50% !important;
          border-right: 1px solid #e2e3e8;
        }
      }

      border-right: 1px solid #e2e3e8;
    }
  }

  @media (max-width: 992px) {
    .ant-modal {
      height: 100%;
    }
  }

  @media (min-width: 992px) {
    --modal-gap: 25px;

    .ant-modal-content {
      height: calc(100vh - var(--modal-gap) - var(--modal-gap));
    }
  }
}
@import "styles/theme.scss";

.proposal-headersection {
  box-shadow: none !important;
  margin-bottom: 0px;
  padding: 50px;
  border-bottom: 0px none #fff;
  background-color: #fff;
  transition: background 0.5s ease;
  background-image: none !important;
  background-position:
    0px 0px,
    0px 0px;
  background-size: auto, cover;
  position: relative;

  @media (max-width: 991px) {
    padding: 40px;
  }

  p {
    margin-bottom: 0;
    word-break: break-word;
  }

  .cover {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover !important;
    opacity: 1;
  }

  .header-color {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
  }

  .cover-buttons-container {
    position: absolute;
    left: 8px;
    top: 8px;
    z-index: 10;

    .settings-btn {
      padding: 0 11px;
      margin-bottom: 10px;
  
      .anticon {
        width: 24px;
        transition: 0.2s;
      }
  
      .cross-icon {
        position: absolute;
      }
    }
  
    &.active .control-icon,
    &.inactive .cross-icon {
      opacity: 0;
      transform: rotate(45deg) scale(0);
    }
  }

  .cover-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .cover-buttons-group {
    background-color: $white-color;
    font-size: $label-font-size;
    font-weight: normal;
    line-height: 140%;
    text-align: center;
    color: $primary-color-text;
    display: flex;
    box-shadow: 0px 2px 4px rgba(0, 38, 95, 0.2);
    border-radius: 4px;
    & > span {
      cursor: pointer;
      padding: 6px 8px;
      display: flex;
      align-items: center;
      height: 28px;
      vertical-align: middle;
      &:not(:last-child) {
        border-right: 1px solid #cbced8;
      }
      &:hover {
        background-color: #f5f6fa;
      }
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
        span.remove-cover-icon {
          height: auto;
          padding: 0;
        }
      }
    }
    &.rtl {
      & > span {
        &:not(:last-child) {
          border-left: 0;
          border-right: 1px solid #cbced8;
        }
        &:hover {
          background-color: #f5f6fa;
        }
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
          span.remove-cover-icon {
            height: auto;
            padding: 0;
          }
        }
      }
    }
  }

  .logo-section {
    cursor: pointer;
    position: relative;
    flex-shrink: 1;
    flex-grow: 0;
    .logo {
      min-width: 50px;
      width: 100%;
      max-width: 200px;
    }
    .logo-hover {
      min-height: 100px;
      min-width: 100px;
      height: 100%;
      margin: 0px;
      width: 100%;
      display: flex;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      img.wrapper-logo,
      .remove-logo {
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
        opacity: 0;
        transition: opacity 500ms;
      }
      transition:
        background-color 500ms,
        opacity 500ms,
        border 500ms;
    }

    .logo-hover:hover,
    img.logo-hover {
      background-color: rgba(0, 0, 0, 0.8);
      border: 1px solid #fff;
      img.wrapper-logo,
      .remove-logo {
        opacity: 1;
      }
    }

    .remove-logo {
      position: absolute;
      top: -17px;
      right: -34px;
      padding: 11px;
    }

    .logo-upload-progress {
      position: absolute;
      top: 0;
      text-align: center;
      background-color: #ffffffdd;
      display: flex;
      padding: 25px 25px 10px 25px;
      flex-direction: column;
      min-height: 200px;
      min-width: 200px;
      max-width: 200px;
      .spacer {
        flex-grow: 1;
      }
      color: $primary-color-text;
      .progress-title {
        margin-bottom: 2px;
        font-weight: 600;
        font-size: 14px;
      }
      .upload-image-name {
        margin-bottom: 0;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      // .upload-progress-bar {
      .ant-slider {
        .ant-slider-step {
          border-radius: 4px;
        }
        .ant-slider-track {
          background-color: $secondary-color !important;
        }
        .ant-slider-handle {
          background-color: $secondary-color !important;
          border: solid 2px $secondary-color !important;
          border-color: $secondary-color !important;
          box-shadow: 0px 0px 4px $secondary-color;
        }
      }
      // }
      .ant-tooltip-content {
        color: white;
        background-color: white;
        .ant-tooltip-arrow-content {
          background-color: white !important;
        }
        .ant-tooltip-inner {
          color: #05034d !important;
          background-color: white;
        }
      }
    }
  }

  .text-section-col{
    position: relative;
    z-index: 10;
  }

  .titles {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    // font-size: 36px;
  }

  .titles p {
    margin: 0px;
  }

  .titles.proposalheaderwhite {
    margin-right: 0px;
    color: $white-color;
    letter-spacing: 0px;
    margin: 0px;
    font-size: $medium-title-font-size;
    line-height: 1.4;
    position: relative;
    // for background-color apply the styles to inner span also, else there's issue with line-height background color
    p>span>span {
      background-color: inherit;
    }

    @media (max-width: 480px) {
      margin-bottom: 2px !important;
    }
  }

  .titles.proposalheaderwhite.lightgrey {
    color: #d2d2d2;
  }

  .header-by-container {
    margin-top: 1em;
    .contact-by {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      color: $white-color;
      font-size: 16px !important;
      line-height: 23px;
      letter-spacing: 1px;
      overflow-wrap: break-word;
    }
    .contact-by-values {
      color: #d2d2d2;
      font-size: $label-font-size !important;
      letter-spacing: 1px;
      text-transform: none;
    }
  }
  .desktop-screen {
    .header-by-container {
      .contact-by-values-custom {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  @media (max-width: 599.9px) {
    .cover-buttons-container {
      &.inactive {
        .cover-buttons {
          display: none;
        }
      }
    }
  }
  @media (min-width: 600px) {
    .cover-buttons-container {
      .settings-btn{
        display: none;
      }
    }
  }
}

.proposalheader-left {
  .public-DraftStyleDefault-block,
  .public-DraftEditorPlaceholder-root {
    text-align: left !important;
  }

  .proposalheaderwhite {
    text-align: left;
  }

  .resizable-container {
    direction: ltr;
  }

  .header-by-container {
    text-align: left;
  }
}

.rich-editor .DraftEditor-root .public-DraftEditorPlaceholder-root {
  width: 100%;
}

.header-title-container {
  line-height: 44px;
  position: relative;
  > .ant-col .ant-row {
    flex-flow: column;
  }
}

.proposalheader-custom {
  .header-title-container {
    .ant-col {
      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .proposalheaderwhite {
          .public-DraftStyleDefault-block,
          .public-DraftEditorPlaceholder-root {
            direction: initial !important;
            text-align: right !important;
            font-size: 24px !important;
          }
        }

        .ant-row {
          flex-flow: column;

          .public-DraftStyleDefault-block,
          .public-DraftEditorPlaceholder-root {
            direction: initial !important;
            text-align: center !important;
          }

          .resizable-container {
            direction: inherit;
            .react-resizable,
            .title-under-line {
              margin: auto;
            }
          }
        }
      }
    }
  }
  .header-by-container {
    position: absolute;
    bottom: 50px;
    width: 88.89%;
  }
}

.proposalheader-center {
  .public-DraftStyleDefault-block,
  .public-DraftEditorPlaceholder-root {
    direction: initial !important;
    text-align: center !important;
  }

  .proposalheaderwhite {
    text-align: center;
  }

  .resizable-container {
    direction: inherit;

    .react-resizable,
    .title-under-line {
      margin: auto;
    }
  }

  .header-by-container {
    text-align: center;
  }
}

.proposalheader-right {
  .public-DraftStyleDefault-block,
  .public-DraftEditorPlaceholder-root {
    direction: rtl !important;
    text-align: right !important;
  }

  .proposalheaderwhite {
    text-align: right;
  }

  .resizable-container {
    direction: rtl;
  }

  .header-by-container {
    text-align: right;
  }
}

.resizable-container {
  z-index: 1;
  padding: 0 4px;
  .title-under-line {
    height: 1px;
    border-bottom: 1px solid #fff;
  }
  & > .box {
    display: flex;
    align-items: center;
    max-width: 100%!important;
    .title-under-line{
      max-width: calc(100% - 0px)!important;
    }
    .anticon.anticon-eye-invisible {
      padding: 10px;
    }
  }
  
  .anticon {
    opacity: 0;
    position: absolute;
  }

  &:hover {
    .anticon {
      opacity: 1;
    }
  }

  .anticon-eye{
    right: -20px;
  }
  .anticon-arrow-right{
    right: -38px;
  }
}

.proposalheader-left .resizable-container {
  direction: ltr;
}
.proposalheader-right .resizable-container {
  .anticon {
    right: unset;
  }
  .anticon-eye {
    left: -20px;
  }
  .anticon-arrow-right {
    left: -38px;
    transform: rotate(180deg);
  }
}


.react-resizable-none .react-resizable-handle {
  display: none;
}

.ant-tooltip-inner,
.ant-tooltip-arrow-content {
  background-color: white !important;
}

@media (max-width:768px) {
  .section-wrapper .proposal-headersection {
    .header-title-container {
      .logo-section-col {
        position: relative !important;
        z-index: 2 !important;
      }
    }
  }
}
@include xs {
  .section-wrapper .proposal-headersection {
    padding: 10px !important;
    .header-title-container {
      height: auto !important;
      margin-bottom: 0 !important;
      .proposalheaderwhite {
        margin-bottom: 1em;
      }
      .logo-section-col {
        position: relative !important;
        margin-bottom: 1em;
      }
    }
    .header-by-container {
      position: relative;
      padding: 0;
    }
  }
}

.mobile-screen {
  .header-by-container {
    .ant-col-lg-offset-8 {
      margin-left: 0;
    }
  }
}

.section-action-add-to-library {
  position: absolute;
  top: 2.2em;
  box-shadow: $box-shadow;
  border-radius: 4px;
  background: white;
  padding: 6px;
  text-align: initial;
  .anticon {
    margin: 5px;
    cursor: pointer;
    &:hover {
      svg rect:first-child {
        fill: #c7bce4;
      }
    }
  }
}

.section-action-add-to-library {
  padding: 1em;
  width: calc(100% - 16px);
  z-index: 15;
  top: 3em;
  left: 8px;
  max-width: 400px;

  .section-action-add-to-library-header {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.04em;
    color: #05034d;
  }

  .section-action-add-to-library-input {
    margin: 0.5em 0;
    .ant-form {
      width: 100%;
      .ant-form-item {
        margin-bottom: 0.5em;
      }
    }
    input {
      background: #ffffff;
      border: 1px solid $primary-color;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }

  .section-action-add-to-library-list {
    max-height: 400px;
    overflow-y: auto;

    .split-d1 {
      width: 100%;
      margin-bottom: 1em;
      .ant-tree {
        margin: 0;
      }
      .ant-tree-treenode {
        .anticon-file {
          display: none;
        }
      }

      .ant-tree-title {
        margin: 0 0.5em;
        .content-library-tree-title .anticon {
          display: none;
        }
      }

      button {
        display: none;
      }
    }
  }

  .save-header {
    width: 100%;
  }

  @media (max-width: 599.9px) {
    top: 90px;
    .ant-tree-switcher {
      width: 10px;
    }
  
    .ant-tree-switcher-icon {
      margin: 0;
    }
  }
}

.language-rtl {
  direction: rtl;

  .resizable-container {
    .anticon {
      right: unset;
      transform: rotate(180deg);
    }
    .anticon-eye {
      left: -20px;
    }
    .anticon-arrow-right {
      right: unset;
      left: -38px;
    }
  }

  .proposalheader-left .resizable-container {
    .anticon {
      left: unset;
      transform: rotate(0);
    }
    .anticon-eye {
      right: -20px;
    }
    .anticon-arrow-right {
      right: -38px;
    }
  }

  .section-action-add-to-library {
    left: 0;
    right: 1%;
  }
}
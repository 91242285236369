@import 'styles/theme.scss';

.crop-container {
  position: relative;
  width: 100%;
  height: 200px;
  background: '#333';
}

.image-crop-label {
  font-size: 0.7em;
}

.image-action-wrapper {
  padding: 10px;

  .ant-col {
    flex: 1;
    min-width: 120px;
  }
}

.crop-dimension-wrapper {
  display: flex;
  flex-wrap: wrap;

  .ant-btn {
    flex: 1 1 auto;
    max-width: 50%;
    padding: 2px;
    height: 24px;
    border-radius: 0;
    border: 1px solid $border-color-tab;
    &:not(:first-child){
      border-left: unset;
    }

    &:nth-child(1){
      border-radius: 5px 0 0 5px;
    }
    &:last-child{
      border-radius: 0 5px 5px 0;
    }
    &:hover{
      border-color: $border-color-tab;
      &:not(.ant-btn-primary){
        color: $section-color;
      }
    }
    &.ant-btn-primary{
      background-color: $section-color;
      border: 1px solid $section-color;
    }
  }
}

.simple-section-content {

  &>.ant-col-6,
  &>.ant-col-8,
  &>.ant-col-12 {
    .crop-dimension-wrapper {
      .ant-btn {
        width: 50%;
      }
    }
  }
}

.image-component-container.react-resizable {
  text-align: center;
  max-width: 100%;
  transform: translateX(-1px);
  border: unset !important;

  img {
    object-fit: cover;
  }
}

.image-viewer-container {
  img {
    width: 100%;
  }
}


.edit-image-action-btns {
  display: flex;
  padding: 10px;
  gap: 10px;

  .ant-btn {
    flex: 1;
    font-weight: 600;
    max-width: 100px;
  }
}
@import 'styles/theme.scss';

.device-toggler {
  label {
    &:before {
      display: none !important;
    }

    &:first-child {
      border: unset;
    }
  }

  @media (max-width: 992px) {
    .text {
      display: none !important;
    }
  }
}

.toggle-mobile-desktop {
  background: $light-color;
  border-radius: 4px;
  font-weight: 600;

  .ant-radio-group {
    padding: 3px;
    display: flex;
  }

  label {
    height: 34px;
    padding: 0 10px;
    font-size: 12px;
    background: none !important;
    border: 0 !important;
    display: flex;
    box-shadow: none !important;
    border-radius: 3px !important;

    &> :hover,
    &> :active {
      opacity: 0.7;
    }

    &>span {
      @extend .flex-center;
      gap: 4px;
    }

    span {
      color: $primary-color-text;
    }
  }

  .anticon {
    @extend .flex-center;
    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .desktop .anticon {
    transform: translateY(-2px);
  }

  .ant-radio-button-wrapper-checked {
    background: $white-color !important;
  }

  .text {
    text-transform: uppercase;
  }

  @media (min-width: 992px) {
    label {
      height: 28px;
    }
  }
}


.wixScreen-switcher {
  height: 33px;
  border: 1px solid;
  border-color: #D6E6FE;
  border-radius: 5px;
  background-color: #fff;

  .ant-radio-group {
    display: flex;
    line-height: 0px;
  }

  label {
    display: flex;
    align-items: center;
    border: unset;
    background-color: transparent;

    span:last-child {
      display: flex;
      align-items: center;
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-radius: 4px !important;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      box-shadow: 0px 0px 0px 2px #116DFF !important
    }
  }

  .text {
    color: #000;
    font-size: 13px;
    font-weight: 300;
    margin-left: 6px;
  }
}
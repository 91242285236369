@import "styles/theme.scss";

.emails-wrapper {
    .email-profile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;

        span {
            color: $label-color-light;
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;
        }

        .sender-image {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        img {
            max-height: 73px;
        }
    }

    .email-accordion-header {
        gap:4px;
        .email-accordion-title {
            color: $primary-color-text;
            font-size: 14px;
            font-weight: 600;
            line-height: 140%;
            /* 19.6px */
        }
    }

    .accordion-wrapper {
        display: inline-flex;
        margin-top: 15px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        width: 100%;

        >div {
            width: 100%;
        }
    }

    .email-card-row{
        gap: 20px;
    }
    .email-info-row {
        gap: 16px;
        justify-content: space-between;
        .email-action-button {
            margin-left: auto;
        }
    }

    .email-status-info{
        flex: 1;
        flex-wrap: nowrap;
    }

    .email-info-col {
        flex: 1;
    }

    .info-list {
        max-width: 500px;
        width: 100%;
        .email-status-info {
            align-items: center;
            gap: 8px;
    
            .text {
                color: $primary-color-text;
                font-size: 12px;
                font-weight: 400;
                opacity: 0.8;
            }
    
            .count {
                color: $primary-color-text;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    @media screen and (max-width: 575px) {
        .email-info-row {
            flex-direction: column;
        }
    }
    @media (min-width: 576px) and (max-width: 1199px) {
        .info-list {
            min-width: 300px;
            width: calc(100% - 142px);
        }
    }
 
    @media screen and (min-width: 900px) {
        .accordion-wrapper {
            padding: 32px;
            background: $white-color;
            border-radius: 8px;
            box-shadow: 0px 2px 4px 0px rgba(3, 20, 63, 0.10);
        }
    }
    @media (min-width: 1400px) {
        .info-list {
            width: calc(100% - 142px);
        }
    }
    
}
@import 'styles/theme.scss';

.simple-info-modal.create-duplicate-modal {
  width: 28em !important;
  .ant-modal-content {
    .ant-modal-body {
      padding: 2.5em;
      .title {
        margin-top: 0;
        justify-content: flex-start;
      }
      .divider {
        margin: 1em 0;
      }
      .body {
        margin-bottom: 4.5em;
        .ant-form {
          .ant-row {
            .ant-form-item-label {
              color: $label-color-light;
              letter-spacing: 1px;
            }
            .ant-form-item-control {
              .ant-form-item-control-input {
                .ant-form-item-control-input-content {
                  input {
                    border: 1px solid $primary-color-light;
                    border-radius: 4px;
                    color: #05034d;
                  }
                  .ant-select {
                    .ant-select-selector {
                      border: 1px solid $primary-color-light;
                      border-radius: 4px;
                      input {
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .button-wrapper {
        padding: 0;
        button.ant-btn {
          width: 100%;
          height: 44px;
        }
        .ant-btn:nth-child(1) {
          margin-right: 1em;
        }
        .ant-btn:nth-child(2) {
          margin-left: 1em;
        }
      }
    }
  }

  .autocomplete-wrapper {
    width: 100%;
  }

  &.hebrew, &.arabic {
    .autocomplete-wrapper input,
    .ant-form-item-label {
      text-align: right;
    }
    .ant-input {
      direction: rtl;
    }
    .ant-select-selector {
      text-align: right !important;
    }
  }
}

.ant-select-item-option.hebrew, 
.ant-select-item-option.arabic {
  direction: rtl;
}

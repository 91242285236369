@import "styles/theme.scss";

.activity-filter {
  .item-filter-menu {
    margin-top: 2em;
  }

  .item-filter-title {
    font-weight: 600;
    font-size: 24px;
    color: $primary-color-dark;
  }

  .search-filter-col {
    padding: 4px;
  }

  .item-filter-value {
    font-weight: normal;
    font-size: 12px;
    color: $primary-color-dark;
    opacity: 0.7;
  }

  .ant-picker {
    padding-left: 0px !important;
    padding-top: 0.5em;
  }

  .date-picker {
    width: 100%;

    .ant-picker-active-bar {
      bottom: 4px !important;
      margin-left: 0 !important;
    }

    .ant-picker-clear {
      right: -7px !important;
      bottom: 10px;
      background: transparent !important;
    }

    .ant-picker-input {
      border-bottom: 2px solid #e1e2ef !important;

      input {
        text-align: left;
        font-size: 18px !important;
        font-weight: 300 !important;
        color: $primary-color-text;
      }

      .ant-picker-clear {
        padding: 2px 3px 2px 4px;
        background: #f0f2f5;
      }
    }
  }

  .search-filter {
    .ant-space-item {
      height: 24px;
      margin-right: 0px !important;
    }
  }

  .search-input-row {
    display: flex;
    height: 24px;

    .anticon {
      margin-left: -5px;
      z-index: 11;
    }
  }

  .icons-container {
    display: flex;
    align-items: center;

    .anticon {
      height: 32px;
      width: 32px;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;

      &:hover,
      &.anticon-active {
        background: $button-icon-hover;
        border-radius: 3px;
      }

      svg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .search-activity {
      border-radius: 6px;
      border: none;
      transition: max-width 500ms, opacity 500ms;
      max-width: 0;
      opacity: 0;

      &.showInput {
        max-width: 370px;
        margin-left: 1em;
        opacity: 1;
      }
    }

    .search-activity:hover,
    .search-activity:focus {
      border-color: $border-color important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      box-shadow: none;
    }

    .vertical-divider {
      height: 1.1em;
      border-left: 1px solid #e2e3e8;
    }

    .content-search-icon {
      transition: transform 500ms, opacity 500ms, max-width 500ms;
      opacity: 1;
      max-width: 80px;

      &.hide-search {
        opacity: 0;
        transform: scale(0);
        max-width: 0;
      }
    }
  }

  .divider {
    width: 1px;
    height: 30px;
    background: #e2e3e8;
    border-radius: 24px;
    margin: 0 20px;
  }

  @media screen and (max-width: 575.9px) {
    .search-filter-col{
      position: absolute;
      top: -50px;
      right: 0;
    }
  }
  @media screen and (max-width: 767px) {
    .icons-container {
      justify-content: flex-end;
    }
    .activity-rate {
      justify-content: start !important;
    }
  }
}

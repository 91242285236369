.is-editing-modal.simple-info-modal {
  .ant-modal-close {
    cursor: auto;
  }
  button.ant-btn {
    flex: 1;
    width: auto;
    max-width: 147px;
  }
  .button-wrapper{
    padding: 0;
    gap: 20px;
  }
}
@import 'styles/theme.scss';

.rich-editor-price-edit {
  .ant-modal {
    width: 100% !important;
    max-width: 100% !important;
    height: var(--height-100);
    top: 0;
    padding: 0 !important;
    margin: 0 !important;
    @extend .flex-center;
    flex-direction: column;

    @include rwd-min(1024) {
      padding:20px!important;
    }
    @include rwd-min(1200) {
      padding-left:80px!important;
      padding-right:80px!important;
    }

    .ant-modal-header{
      padding-bottom:0;
    }

    .rich-editor-price-edit-header {
      .title {
        margin: 0;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: $primary-color-text;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .rich-editor-title-info {
          background: $background-color;
          border-radius: 32px;
          padding: 0 8px;
          font-size: 11px;
          font-weight: 400;
          line-height: 1.8;
        }
      }

      .ant-divider {
        background: $secondary-color;
        width: 32px;
        height: 1px;
        margin: 12px 0 0;
        min-width: 0;
      }

      @media (min-width: 568px) {
        .title {
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }
      }
      @media (min-width: 992px) {
        .title {
          font-size: 24px;
        }
      }
    }

    @media (max-width: 1199.9px) {
      .ant-modal-header {
        border: unset;
      }
    }

    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      max-width: 1400px;
      
      .ant-modal-body {
        padding: 0;
        flex: 1;
        position: relative;
        overflow: hidden;
        .tablist-container{
          position: unset;
        }
        .tablist-container .tablist-content {
          overflow: auto;
          padding-bottom: 10px;
          position: absolute;
          height:100%;
          width:75%;
        }
      }
      .ant-modal-footer {
        padding: 0;
        border-top: 1px solid $tab-border;
        z-index: 11;
      }
    }

    .rich-editor-modal-footer {
      .rich-editor-modal-continue-disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &.rich-editor-price-edit-hebrew {
    .tablist-container {
      .tablist-prelist-container {
        .tablist-prelist {
          .tablist-prelist-input {
            .input-container {
              input,
              .ant-input-affix-wrapper {
                direction: rtl;
              }
            }
          }

          .tablist-prelist-content {
            .ant-list-item {
              direction: rtl;
              text-align: right;
            }
          }
        }
      }

      .tablist-content {
        .tablist-sortlist-container {
          .tablist-sortlist-content {
            .tablist-input-container {
              .input-container,
              .select-container {
                .ant-row {
                  text-align: right;
                  .input-label {
                    direction: rtl;
                    width: 100%;
                  }
                }
                input,
                textarea,
                .ant-select-selection-placeholder {
                  direction: rtl;
                }
              }
            }

            .super-textarea,
            .miles-input,
            .item-name-wrap,
            .tablist-input-description {
              text-align: right;
            }
            .super-textarea textarea {
              padding-right: 0 !important;
            }
          }
        }
      }
    }
  }

  @include rwd-min(992) {
    .ant-modal-content {
      max-height: 1000px;
    }
  }
  
}

@import 'styles/theme.scss';


.totals-popover-container {
  position: fixed;
  justify-content: flex-end;
  display: flex;
  bottom: 85px;
  right: 0;
  z-index: 10;
  width: 100%;
  padding: 0 16px;

  .total-btn {
    background: #ffffff;
    box-shadow: 0px 0px 15px -2px rgba(3, 20, 63, 0.2);
    border-radius: 68px;
    width: 100%;
    max-width: 400px;
    min-height: 54px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    margin-right: 0;
    justify-content: space-between;

    .icon {
      padding: 0 10px 0 6px;
      position: relative;
      @extend .flex-center;

      &::before {
        content: '';
        width: 1px;
        position: absolute;
        height: 20px;
        width: 1px;
        top: calc(50% - 10px);
        left: 43px;
        background-color: $tab-border;
      }
    }

    .totals-container-tag-info {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      padding-left: 15px;
      flex: 1;
      overflow: hidden;
      gap: 10px;
    }

    p {
      font-size: $label-font-size;
      color: $label-color-light;
      margin: 0;
      line-height: 1.2;
    }

    .totals-container-tag-price {
      color: $primary-color-dark;
      font-size: $medium-text-font-size;
      @extend .text-ellipsis;
    }
  }

  @media (min-width: 768px) {
    position: absolute;
    bottom: 14px;
    right: 14px;

    .total-btn {
      min-width: 250px;
      width: auto;
      max-width: 500px;
    }
  }
}



.totals-popover {
  .ant-popover-arrow {
    display: none;
  }


  &>.totals-content {
    padding: 10px !important;
    overflow-x: auto;
    width: 100%;
  }

  .totals-content-container {
    padding-right: 10px;
    flex-wrap: nowrap;
  }

  .totals-content-wrapper {
    padding-top: 15px;

    .ant-row {
      padding: 5px 10px;
    }
  }

  .totals-content {
    padding: 0.3em 0;
    display: flex;
    color: $primary-color-text;

    .input-container {
      width: auto;
      margin-bottom: 0;

      input {
        border: none;
        border-bottom: 1px solid;
        border-radius: 0;
        text-align: end;
        color: $primary-color-text;
      }
    }

    .ant-select {
      width: 4em;
    }
  }

  .ant-divider-dashed {
    margin: 0.5em 0em;
    border: 1px dashed $primary-color;
  }

  .totals-info {
    width: auto;
    padding-right: 1em;
    display: flex;
    justify-content: flex-end;

    .input-container {
      display: inline-block;
      width: auto;
      min-width: 100%;
      max-width: 210px;
      margin-bottom: 0;

      &:focus {
        outline: none;
      }

      .total-text,
      .dis-text,
      .tax-text,
      .sub-total-text {
        border: none;
        text-align: end;
        padding: 0.5em;
        color: $label-color-light;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .totals-content-unit {
    font-size: $label-font-size;
    line-height: 2.5em;
    color: $primary-color-text;
    opacity: 0.2;
    cursor: pointer;
  }

  .totals-currency {
    line-height: 35px;
  }

  .ant-input {
    background-color: transparent;

    &:focus {
      box-shadow: unset;
    }
  }

  .totals-content-currency {
    width: 100%;
    font-size: $medium-text-font-size;
    color: $primary-color-text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input.totals-content-currency {
    background-color: transparent;
    border: unset;
    border-bottom: 1px solid #05034d;

    &:focus {
      outline: none;
    }
  }

  .totals-content-wrapper {
    .ant-row:last-child {
      background-color: $selected-dropdown-placeholder;
    }
  }

  @media (max-width: 767.9px) {
    .ant-modal-header {
      border: unset;
      padding: 16px 16px 0;

      h1 {
        font-size: 1.2em;
      }
    }

    .totals-container-close {
      display: none;
    }

    .ant-modal-content {
      overflow: hidden;
    }

    .ant-modal-body {
      padding: 0;
    }
  }

  @media (min-width: 768px) {
    margin-left: -10px;
    z-index: 9999;

    .ant-popover-inner {
      border-radius: 6px;
      overflow: hidden;
    }

    .ant-popover-inner-content {
      border-top: 4px solid $secondary-color;
      padding: 0;
    }

    .totals-container-close {
      position: absolute;
      justify-content: flex-end;
      display: flex;
      width: 100%;

      .totals-container-close-button {
        background: $secondary-color;
        border-radius: 4px 4px 0px 0px;
        width: 7em;
        top: -25px;
        position: absolute;
        cursor: pointer;
        font-size: $label-font-size;
        color: white;
        border: none;
      }
    }
  }
}


.wizard-body {
  @media (min-width :992px) and (max-width :1199.9px) {
    .totals-popover-container {
      bottom: 100px;
    }
  }
}
@import 'styles/theme.scss';

.proposal-header {
  position: fixed;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
  border-bottom: solid 1px #e8e8e8;
  background-color: #fff !important;
  box-shadow: 0px 2px 4px rgba(7, 5, 60, 0.1);

  .prospero-logo {
    width: 64px;
    height: 100%;
    @extend .flex-center;
  }

  .main-wrapper {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .btn-wrapper {
      display: flex;
      align-items: center;
    }

    .block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 0 12px;
      border-left: 1px solid $tab-border;
    }

    .block-1 {
      flex: 1;
    }

    .block-2 {
      padding: 0 10px;
    }

    .block-last {
      padding: 0 16px;
    }
  }

  .wizard {
    .block-1 {
      justify-content: flex-end;
      gap: 10px;
    }
  }

  .draft {
    .icon-block {
      &.below-lg {
        gap: 10px;
      }

      svg path {
        fill: currentColor;
      }
    }

    .large-btn {
      &.icon-btn {
        padding: 0 10px;
      }

      .anticon {
        width: 20px;
      }
    }

    .option-block {
      gap: 10px;
      .ant-divider {
        margin: 0 10px;
      }

      .anticon {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .option-btn {
      padding: 0px 10px !important;
      height: 40px !important;
      font-weight: 400 !important;
      width: 42px;

      &>span {
        vertical-align: middle;
      }

      &.ant-dropdown-open,
      &:hover {
        background-color: #f5f4f9 !important;
      }
    }


    .editor-save-block {
      gap: 20px;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    .save-proposal.anticon {
      cursor: pointer;

      svg {
        width: 1.7em;
        height: 1.7em;
      }
    }
  }

  .publish {
    .block-1 {
      padding: 0 16px;
    }
  }


  .proposal-link-container {
    padding-right: 10px;

    .proposal-link-input {
      width: 100%;
      max-width: 300px;
      height: 32px;
      border-radius: 4px;

      &:focus,
      &:hover,
      &.ant-input-affix-wrapper-focused {
        border: 1px solid $border-color-base;
        box-shadow: 0 0;
        outline: none;
      }
    }
  }

  .preview-button {
    transition: none;

    &:not(:hover) {
      background: $secondary-color-light !important;
      color: $secondary-color !important;

      svg>path {
        fill: $secondary-color;
      }
    }
  }

  .copy-btn {
    background-color: $secondary-color !important;
    color: $white-color !important;
    box-shadow: 0 10px 15px -4px rgba(241, 92, 60, 0.4) !important;
    border-radius: 4px !important;
    border: unset !important;
    box-shadow: unset !important;

    .anticon {
      height: 20px;
    }

    svg>* {
      fill: $white-color;
    }

    .ant-btn {
      background-color: transparent;
      border: unset;
      color: inherit;
      font-weight: 600 !important;
      @extend .flex-center;

      span {
        margin: 0;
      }

      &:first-child {
        border-right: 1px solid #ffffff3b;
      }

      &:after {
        display: none;
      }
    }
  }

  .item-name {
    font-style: normal;
    font-weight: normal;
    font-size: $label-font-size;
    line-height: 140%;
    color: $primary-color-text;
  }

  .ant-btn-group>.ant-btn:first-child {
    z-index: 0;
  }

  @media (max-width: 568px) {}

  @media screen and (max-width: 992px) {
    .draft {

      // hide vertical border
      .block {
        border-left: unset;
      }

      // hide button text for smaller screen
      .option-btn span:nth-child(2) {
        display: none;
      }

      .block-last {
        padding-left: 0;
      }
    }

    .publish {
      .block-1 {
        padding: 0;

        .back-text {
          display: none;
        }
      }

      // hide vertical border
      .block {
        border-left: unset;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .draft {

      // hide the divider in proposal header button (for editor)
      .option-block .ant-divider {
        display: none;
      }
    }

    .ant-btn.preview-button,
    .ant-btn.publish-btn {
      height: 40px !important;
      box-shadow: unset !important;
    }
  }

  @media screen and (min-width: 992px) {
    .wizard {
      .block-1 {
        display: none;
      }
    }

    .preview-button {
      font-weight: 600 !important;
      text-transform: capitalize;
    }
  }

  @media screen and (min-width: 1200px) {
    .draft {
      .block-1 {
        padding-right: 20px;
      }
    }
  }
}

.publish-button-menu {
  .ant-dropdown-menu-item {
    display: flex;
    position: relative;

    .copy-unshortened-link svg {
      height: 28px;
      width: 32px;
      fill: #7876c1;
    }

    .publish-button-menu-title {
      padding: 0.3em;
    }

    &.unpublish-button {
      background-color: $secondary-color;
      color: $white-color;

      &:hover {
        background-color: $secondary-color-hover;
      }

      .anticon {
        svg {
          width: 30px;
          height: 28px;

          path {
            stroke: $white-color;

            &:last-child {
              fill: $white-color;
            }
          }
        }
      }
    }
  }
}

.draft-body,
.publish-body {
  .proposal-header {
    @include sm() {
      .toggle-mobile-desktop {
        display: none;
      }
    }

    @include md() {

      .save-proposal,
      .seprator {
        display: none;
      }
    }
  }
}

// when sidebars are active make buttons active
.proposal-header .settings-btn.active,
.sidemenu-active .proposal-header .sidemenu-btn,
.content-table-active .proposal-header .toc-btn {
  background-color: $secondary-color-light !important;
  color: $secondary-color-hover !important;
}

.header-settings-dropdown {
  width: 100%;
  max-width: 450px;
  background: #fff;
  left: unset !important;
  right: 0;
  top: 63px !important;
  box-shadow: -10px 10px 10px 0 rgba(0, 0, 0, 0.2);
  border-top: 1px solid $border-color;

  >div {
    padding: 10px 0;
  }

  .option-btn {
    width: 100%;
    height: 52px !important;
    gap: 10px !important;
    justify-content: flex-start !important;
    padding: 12px 15px !important;
    cursor: pointer;
    font-weight: 400!important;

    .anticon {
      width: 20px;
      height: 20px;
      justify-content: center;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05)!important;
    }
  }
}
@import "styles/theme.scss";

.preview-proposal-modal {
  padding: 0!important;
  .ant-modal-content {
    height: calc(100vh - 20px);
    border-radius: 6px;
    overflow: hidden;
  }
  .ant-modal-body {
    padding: 0;
  }
  .gap{
    display: none;
  }
  .modal-header {
    @include lg() {
      .title {
        display: none;
      }
      .proposal-button-right {
        position: fixed;
        bottom: 21px;
        z-index: 10;
        left: calc(50% - 100px);
        width: 200px;
        height: 50px;
        border: unset;
        border-radius: 50px;
        font-weight: 500;
        font-size: 1.2em;
        color: #fff;
        background: $section-color;
        box-shadow: 0 6px 20px 0 #005aff69;
      }
    }
  }
  .publish-content {
    padding: 0;
    background-color: #fff;
    // background-color: lightgray;
    overflow-y: auto;
    border: 1px solid lightgray;
    max-height: calc(100vh - 120px);
    #section-content-container, > div {
      pointer-events: none;
    }
  }
  .preview-cover-modal {
    width: 100%;
    max-height: calc(100vh - 100px);
    text-align: center;
    .ant-image-img{
      height: calc(100vh - 120px);
      object-fit: contain;
    }
  }
  .preview-media-modal {
    width: 100%;
    height: 75vh;
  }
  @include rwd-min(992) {
    padding: 20px!important;
    .ant-modal-content {
      height: calc(100vh - 40px) !important;
    }

    .modal-header {
      padding: 20px;
      justify-content: space-between;

      .proposal-button-right {
        margin-right: 40px;

        &:hover {
          background-color: #F5F4F9 !important;
          border: 0;
          border-radius: 4px !important;
          color: $heading-color !important;
        }
      }
    }

    .publish {
      height: calc(100vh - 105px);
    }

    .publish-content {
      padding: 40px !important;
      background-color: #e6e6e6;

      &>div {
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }
}

.title.hebrew {
  direction: rtl;
}
@import 'styles/theme.scss';

.content-library-item {
  display: flex;
  background: $white-color;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 100%;

  .empty-wrapper {
    justify-content: center;
  }

  ul {
    list-style: none;
    padding-left: 10px;

    li {
      color: $primary-color;
      font-size: 14px;
      text-transform: capitalize;
      padding: 10px 0px;
      font-weight: bold;
    }
  }


  .anticon-star:hover {
    opacity: 0.6;
  }


  .split-d1>.sidemenu-list {
    height: calc(100% - 42px) !important;
    overflow-y: auto;
    @include custom-scrollbar;
  }

  .split-d2 .item-row {

    &.covers-card,
    &.medias-card {
      .ant-card-cover {
        padding-top: 6 / 13 * 100%;
      }
    }

    .content-library-card .card-content {
      // padding-top: 171 / 226 * 100%;
      padding: 0;
      overflow: hidden;
      position: relative;

      .card-description {
        padding: 16px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: auto;
        width: 100%;
      }
    }

    &:not(.covers-card):not(.medias-card) .ant-card-cover {
      padding-top: 13 / 16 * 100%;
    }

    .ant-card-cover {
      position: relative;
      overflow: hidden;

      .logo {
        position: relative;
      }

      >img,
      >.ant-image>img,
      >.mask-class-name,
      >.ant-image {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .loader {
        top: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 34px;
        height: 34px;
      }
    }
  }

  .split-d2 {
    .divider-line {
      margin: 20px 0;
      height: 1px;
      background: #e8e8eb;
      margin-bottom: 1.2em;
      position: relative;
    }


    .full-page-covers {
      .item-row {
        &.covers-card {
          .ant-card-cover {
            padding-top: 18 / 13 * 100%;
          }
        }
      }
    }

    .item-row:not(.empty-wrapper) {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;

      .ant-card-body {
        padding: 0;
      }

      @media screen and (min-width: 480px) and (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (min-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (min-width: 1400px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and (min-width: 1920px) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media screen and (min-width: 2560px) {
        grid-template-columns: repeat(6, 1fr);
      }
    }

    .ant-card {
      border-radius: 8px;
      width: 100%;
    }

    .add-folder {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f6fa;
      padding: 10px;
      color: $primary-color;
      font-size: 14px;
      font-weight: bold;
      border-radius: 4px;
      width: 80%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      float: none;
      margin: auto;

      .add-title {
        padding-left: 10px;
      }
    }

    .all-starred {
      padding-left: 28px;
      display: flex;
      align-items: center;

      .anticon {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .star-icon {
        display: inline-block;
        margin: 2px;
      }
    }

    .ant-tree-title,
    .star-title {
      color: $primary-color;
      text-transform: capitalize;
      padding: 7px 5px;
      font-size: $label-font-size;
      line-height: 140%;
    }

    .stared-header {
      color: $primary-color-text;
      font-weight: bold;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
    }

    .star-badge {
      padding-left: 12px;

      .ant-scroll-number {
        background: #f5f6fa;
        color: $primary-color-text;
        padding: 2px;
        font-weight: 400;
        width: 42px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .split-d1,
    .split-d2 {
      height: calc(100vh - 246px);
      min-height: 150px;
    }
  }

  &.content-library-item-section {
    .covers-card {
      .content-library-card {
        height: 16.5em;
        width: 100%;

        .ant-card-body {
          height: 14em;
          position: relative;
          .card-content {
            height: 19.5em;
            position: relative;
            .card-description {
              overflow: hidden;
              height: 48em;
              transform: scale(0.37, 0.37);
    width: 735px;
              transform-origin: left;
              -webkit-transform-origin: top left;
              img {
                width: 100%;
                max-width: 100%;
                vertical-align: middle;
                display: inline-block;
              }
            }
          }
        }
        .card-title {
          bottom: -33px;
          position: absolute;
        }
      }
    }
  }
}

.content-library-item,
.move-to-library-list {
  .footer-button{
    padding-right: 10px;
  }
}
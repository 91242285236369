@import 'styles/theme.scss';
.settings-menu-wrapper {
  left: auto !important;
  right: 0 !important;
  top: 64px !important;
  max-height: calc(100vh - 64px);
  z-index: 1007 !important;
  flex-direction: column;
  width: 100%;
  background: $white-color;
  overflow: hidden;
  &:not(.ant-dropdown-hidden) {
    display: flex !important;
  }
  .menu {
    padding: 18px !important;
    flex: 1;
    overflow-y: auto;
  }
  & > ul {
    border-bottom-left-radius: 8px;
  }

  .ant-picker-clear {
    width: 21px;
    height: 19px;
  }

  .settings-label {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #4F4E7B;

    & > span:first-child {
      flex-grow: 1;
    }
  }

  .settings-menu {
    margin-top: 0px;
    background-color: #F5F6FA;
    border-radius: 8px !important;
    margin-bottom: 20px;
    border-right: none;
    box-shadow: none;
    &.last-child, &.redirect {
      margin-bottom: 0;
    }
    .form-item {
      display: flex;
      flex-direction: column;
      height: auto !important;
      &:last-child {
        margin-bottom: 20px;
      }
      &.ant-menu-item-selected {
        background-color: #F5F6FA !important;
      }
      &.signed-email {
        display: block;
      }
      .form-label {
        font-size: 14px;
        opacity: 1;
        font-weight: 400;
        color: #8f8fb9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: capitalize;
      }
      .anticon {
        margin-right: 0;
      }
      .beta-tag {
        justify-content: center;
        padding: 4px 16px;
        min-width: 60px;
        width: 60px;
        height: 26px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        letter-spacing: .04em;
        background: rgb(252, 243, 236);
        color: rgb(233, 109, 40);
        &.margin-start {
          margin-left: 8px
        }
      }
    
      .blur-overlay {
        position: relative;
        transition: backdrop-filter 0.4s ease, background-color 0.4s ease;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(2px);
          -webkit-backdrop-filter: blur(2px);
          background-color: rgba(245, 246, 252, 0.6);
          transition: backdrop-filter 0.4s ease, background-color 0.4s ease;
          pointer-events: none;
          z-index: 1;
        }
        > * {
          pointer-events: none;
          z-index: 0;
        }
      }
    }

    .ai-tool-toggle {
      display: flex;
      align-items: center;
      button {
        margin-right: 7px;
      }
      .check-label {
        font-size: 16px;
        color: #05034d;
        margin-bottom: 0 !important;
      }
      #show-footer.ant-switch {
        height: 21px;
        min-width: 35px;
      }
      .ant-switch-handle {
        top: 3px;
        width: 15px;
        height: 15px;
      }
      .ant-switch-checked {
        background-color: #2f2c88;
        .ant-switch-handle {
          left: calc(100% - 16px - 2px);
        }
      }
    }

    .email-error {
      border-color: $error-color;
    }

    .contact-person-email-error, .redirect-to-error {
      border-color: $error-color;
    }

    .optional-text {
      color: rgba(175, 177, 186, 0.60);
    }

    .call-error {
      border-color: $error-color;
    }

    .whatsapp-error {
      border-color: $error-color;
    }

    .signature-email-error {
      border-color: $error-color !important;
    }

    .ant-dropdown-menu-item {
      background: none !important;
    }

    .date-picker {
      border: 1px solid $tab-border !important;
      border-radius: 4px;
      background-color: white !important;
      &:hover {
        border-color: $tab-border !important;
      }
      input {
        border: 0;
      }
    }

    .ant-select {
      width: 100%;
      &:focus {
        box-shadow: none;
      }
    }

    input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid $tab-border;
      border-radius: 4px;
      color: $primary-color-text;
      &:focus,
      &:hover {
        box-shadow: none;
        border-color: none;
      }
    }

    input::placeholder,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector::placeholder {
      color: rgba(175, 177, 186, 0.6)
    }

    .signature-emails-col {
      display: flex;
      flex-direction: column;
      .ant-input-affix-wrapper {
        border: 1px solid $tab-border;
        border-radius: 4px;
        margin: 0.5em 0;

        &:first-child {
          margin-top: 0;
        }

        &:focus,
        &:hover,
        &:active,
        &:focus-visible,
        &.ant-input-affix-wrapper-focused {
          box-shadow: none;
          border-color: none;
        }

        .ant-input {
          border: none;
        }

        .ant-input-suffix {
          .anticon {
            margin-right: 0;
          }
          svg rect:first-child {
            fill: white;
          }
        }
      }
    }

    .buttons {
      .ant-btn:nth-child(3) {
        margin-left: 15px;
      }
      .anticon{
        margin-right: 0;
        svg{
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  .btn-footer {
    border-top: 1px solid #F5F6FA;
    padding: 10px 18px;
    display: flex;
    gap: 10px;
    .reset-btn {
      padding-right: 0;
      .anticon {
        width: 16px;
        height: 16px;
        svg>path {
          fill: currentColor;
        }
      }
    }
  }

  @media (min-width: 768px) {
    max-width: 450px;
    min-height: 300px;
    max-height: calc(100vh - 140px);
    border-radius: 8px;
    box-shadow: 0px 0.625em 1.25em rgba(4, 3, 49, 0.2);

    .menu {
      @include custom-scrollbar;
    }

    .btn-footer {
      justify-content: flex-end;
      padding: 20px;
      gap: 15px;
    }
  }
}
@import 'styles/theme.scss';

.page-header {
  margin-bottom: 0.85em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  h1 {
    font-size: $large-title-text-font-size;
    font-weight: 600;
    color: $primary-color-text;
    margin-bottom: 0.2em;
    &.settings-title {
      font-size: 35px;
    }
  }

  .color-divider {
    background: $secondary-color;
    width: 32px;
    height: 1px;
    padding: 0 10px;
  }

  .page-title {
    margin-bottom: 10px;
  }

  .button-wrapper {
    display: flex;
    gap: 10px;
  }

  @include rwd-min(1200) {
    h1 {
      font-size:35px;
    }
  }
}
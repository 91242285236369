@import 'styles/theme.scss';

.move-to-folder-modal {
  .body {
    .move-to-library-input {
      margin: 0.5em 0;
      .ant-form {
        width: 100%;
        .ant-form-item {
          margin-bottom: 0.5em;
        }
      }
      input {
        background: #ffffff;
        border: 1px solid $primary-color;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }

    .move-to-library-list {
      max-height: calc(100vh - 300px);
      overflow-y: auto;

      .error {
        color: red;
      }

      .split-d1 {
        width: 100%;
        margin-bottom: 1em;

        .ant-tree {
          margin-right: 0;
        }

        .ant-tree-treenode {
          .anticon-file {
            display: none;
          }
        }

        .ant-tree-title {
          margin-left: 0.5em;
          .content-library-tree-title {
            width: 100%;
            text-align: left;
            .anticon {
              display: none;
            }
          }
        }

        button {
          display: none;
        }
      }

      .shared-folder,
      .starred-folder {
        display: none;
      }
    }
  }

  &.hebrew, &.arabic {
    .ant-form-item,
    .tree-node-search-input {
      direction: rtl;
    }
  }
}

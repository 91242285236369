@import 'styles/theme.scss';

.trial-end-header {
  top: 0;
  right: 0;
  position: fixed !important;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px!important;
  background-color: $secondary-color-light !important;
  height: 60px;
  transition: 0.4s;
  &.inactive{
    top: -60px;
  }

  .ant-page-header-content {
    padding-top: 0;
    font-weight: 600;
    font-size: 16px;
    color: $primary-color-text;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    p {
      margin: 0;
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  @include rwd-min(768) {
    width: calc(100% - 80px);
  }
  @include rwd-min(1200) {
    padding: 0 50px !important;

    .ant-page-header-content {
      max-width: 900px;
      margin: auto;
      // gap: 20px;
      // justify-content: center;
    }
  }
}
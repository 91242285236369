@import 'styles/theme.scss';

.trial-end-popup {
  width: 775px !important;

  .ant-modal-content {
    border-radius: 0;
    overflow: hidden;
    @include rwd-min(1024) {
      border-radius: 0.5rem;
    }

    @include rwd-max(767) {
      border-radius: 0.7em;
    }

    .ant-modal-body {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding: 0;

      @include rwd-min(768) {
        padding: 0;
      }

      @include rwd-min(1024) {
        padding: 1.2em;
      }

      @include rwd-min(640) {
        flex-direction: row;
        padding: 1.2em;
      }

      .popup-left {
        position: relative;
        background-color: $trial-left;
        height: auto !important;
        min-height: 500px;
        width: 50%;
        overflow: hidden;

        @include rwd-max(767) {
          display: none;
        }

        @include rwd-min(768) {
          width: 100%;
        }

        @include rwd-min(640) {
          border-radius: 0.5rem;
          width: 100%;
        }

        .trial-left-header {
          padding: 1.5em;
          .logo {
            height: 15px;
          }
        }
        .body {
          position: relative;
          margin-top: 2.2rem;
          height: 100%;
          .design-img {
            position: absolute;
            left: 0;
            &:nth-child(1) {
              top: 8px;
              z-index: 1;
              img {
                width: 314px;
              }
            }
            &:nth-child(2) {
              top: 68px;
              img {
                width: 262px;
              }
            }
            &:nth-child(3) {
              top: 131px;
              img {
                width: 230px;
              }
            }
            &:nth-child(4) {
              top: 193px;
              img {
                width: 202px;
              }
            }
            &:nth-child(5) {
              top: 255px;
              img {
                width: 178px;
              }
            }
          }
        }
      }
      .popup-right {
        margin-left: 1.8em;
        width: 50%;
        @include rwd-max(767) {
          margin-left: 0;
          padding: 1.5em;
          width: 100%;
        }

        @include rwd-min(768) {
          width: 100%;
        }

        @include rwd-min(640) {
          border-radius: 0.5rem;
          width: 100%;
        }
        .trial-right-header {
          padding: 1rem 0.5em;
          @include rwd-max(767) {
            padding-top: 0.5em;
          }
          .title {
            font-weight: 600;
            font-size: 20px;
          }
          .sub-title {
            font-size: 12px;
            color: rgba(79, 78, 123, 1);
            margin-bottom: 0;
            span {
              font-weight: 500;
            }
          }
        }
        .body {
          padding: 0.5em;
          .information-header {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 12px;
          }
          .plans-information {
            display: block;
            .feature-list {
              margin-bottom: 10px;
              align-items: center;
              flex-wrap: nowrap;
              flex-direction: row;
              .feature-list-text {
                font-size: 13px;
                color: rgba(5, 3, 77, 1);
              }
              .feature-list-check-icon {
                margin-right: 8px;
              }
            }
          }
        }
        .button-wrapper {
          width: 100%;
          padding: 0.5em;
          button {
            width: 100%;
          }
        }
      }
    }
  }
}

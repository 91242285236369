.rich-editor {
  .link-input-box {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: unset !important;
    padding: 10px;
    background-color: white;
    width: 250px;
    box-shadow: 0 2px 8px rgba(26, 33, 52, .2);
    border-radius: 8px;
    z-index: 2000;
    font-family: var(--font-family);

    &.link-text-and-url {
      align-items: flex-end;
    }

    .input-container {
      width: 100%;
      margin: 0;
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      position: relative;

      .input-icon {
        position: absolute;
        top: 0;
        left: 8px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
      }
    }

    .ant-input {
      width: 100%;
      font-size: 12px;
      padding-left: 34px;
      position: relative;

      &:focus {
        box-shadow: unset;
      }
    }

    .link-save-btn {
      margin-left: 8px;
      width: 60px;
      height: 30px;
    }

    .content {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 10px;
      padding-right: 10px;

      h3 {
        font-size: 15px;
        max-width: 260px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 0;
      }

      a{
        color: #8d92bf;
        font-size: 13px;
        overflow: hidden;
        word-break: break-all;
      }
    }

    .buttons {
      display: flex;

      .button {
        background: #f5f6fa;
        width: 30px;
        height: 30px;
        padding: 7px;
        margin: 0 3px;
        border: none;
        border-radius: 4px;

        &:hover {
          background: #e7f1ff;
        }
      }
    }
  }

  @media (min-width: 720px) {
    .link-input-box {
      width: 450px;
    }
  }
}


.language-rtl {
  .rich-editor {
    .link-input-box {
      .input-container .input-icon {
        left: unset;
        right: 10px;
      }

      .ant-input {
        padding-left: 11px;
        padding-right: 34px;
      }

      .link-save-btn {
        margin-right: 8px;
        margin-left: 0;
      }
    }
  }
}

.link-input-icon {
  z-index: 2000 !important;
}
@import "styles/theme.scss";

.sidebar {
  background: $white-color !important;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
  position: fixed !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

  & * {
    user-select: none;
  }


  .sidebar-wrapper {
    padding: 10px;
    height: 100%;
    @include thin-custom-scrollbar;
    overflow-y: auto;
  }

  .sidebar-wrapper,
  .ant-layout-sider-children,
  .sidebar-upper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .sidebar-logo {
    padding: 13px;
    height: 50px;
    width: 50px;
  }
  .mobile-sidebar-logo{
    width: 100px;
    margin: 5px 0 30px;
  }

  .sidebar-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 10px 0;
  }

  .sidebar-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;

      .anticon::before {
        display: none;
      }
    }

    .anticon {
      height: 37px;
      width: 37px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      margin-bottom: 3px;

      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #9693FF1A;
        display: inline-block;
        z-index: 0;
        border-radius: 8px;
        transform: scale(0.5);
        opacity: 0;
        transition: 0.2s;
      }

      svg {
        height: 25px;
        width: 25px;

        .outline {
          opacity: 0.4;
        }

        .filled {
          display: none;
        }
      }
    }

    &.active .anticon svg {
      .filled {
        display: unset;
      }

      .outline {
        display: none;
      }
    }
  }

  // --------- END sidebar nav items -----------


  .sidebar-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;

    .option-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      padding: 5px 10px;

      .anticon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 20px;
          width: 20px;

          path {
            opacity: 0.4;
            fill: #05034D;
          }

          .filled {
            display: none;
          }
        }
      }

      &.active,
      &:hover {
        .anticon svg {
          path {
            opacity: 1;
          }
        }
      }

      .text {
        margin-left: 12px;
      }
    }
  }

  // --------- END sidebar options -----------




  .HW_badge_cont {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .HW_badge {
      transform: scale(0.5) translateY(-18px) !important;
      color: transparent;
      opacity: 0;

      &.HW_visible {
        opacity: 1;
      }

      &.HW_bounce {
        animation: unset;
        transform-origin: unset;
      }
    }
  }

  // --------- END headway widget -----------

  .ant-avatar {
    transition: 0.2s;

    &:hover {
      @extend .ant-avatar-hover;
      // box-shadow: 0 0 0 2px #fff, 0 0 0 3px $svg-color-dark;
    }
  }

  .ant-dropdown-open .ant-avatar {
    @extend .ant-avatar-hover;
  }


  // --------- START of responsive -----------
  @media (min-height: 700px) {
    .sidebar-items {
      .anticon {
        height: 45px;
        width: 45px;
      }
    }
  }

  @media (min-height: 800px) {
    .sidebar-items {
      gap: 20px;
      margin: 20px 0;
    }

    .sidebar-options {
      gap: 25px;
    }
  }

  // ---- tweak the ui to fit all in screen ----
  &.show-upgrade {
    @media (max-height: 800px) {
      .sidebar-items {
        gap: 10px;
        margin: 0;

        .anticon {
          height: 37px;
          width: 37px;
        }
      }

      .sidebar-options {
        gap: 15px;
      }

      .upgrade {
        &:before {
          top: -8px;
        }
      }
    }

    @media (min-height: 700px) and (max-height: 800px) {
      .sidebar-items {
        .anticon {
          height: 42px;
          width: 42px;
        }
      }
    }
  }

  @include md() {
    flex: unset;
    width: 80% !important;
    max-width: 250px !important;
    left: 0;
    transform: translateX(-100%);

    .sidebar-logo {
      display: none;
    }
    
    
    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &.sidebar-active {
      transform: translateX(0);
    }

    .user-avatar {
      display: none;
    }

    .sidebar-item {
      &.active {
        border-radius: 6px;
        background-color: #9693FF1A;
      }

      .anticon {
        transform: scale(0.8);
      }
    }


  }
}

.sidebar-closing-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #05034d66;
}

.sidebar-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  z-index: 998;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: $box-shadow;

  .sidebar-logo {
    width: 30px;
    height: 30px;
  }

  .right-side {
    display: flex;
    align-items: center;
  }

  .ant-divider-vertical {
    height: 20px;
    margin: 0 0 0 20px;
  }

  .sidebar-opener {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 40px;
    width: 40px;

    div {
      height: 2px;
      width: 20px;
      margin: 2.5px 0;
      background-color: #666583;
      transition: 0.3s;
    }
  }
}

// headway widget frame
.HW_frame_cont {
  top: unset !important;
  bottom: 65px !important;
  left: 77px !important;
  width: calc(100% - 100px) !important;
  max-width: 340px;
}

.ant-avatar-hover {
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px $svg-color-dark;
}


@media screen and (min-width: 767px) {
  .mobile-sidebar-logo{
    display: none;
  }

  .sidebar-gap {
    width: 80px;
  }
 
  .sidebar-header,
  .sidebar-closing-mask {
    display: none;
  }

  .sidebar {
    .close-icon {
      display: none;
    }

    .sidebar-wrapper {
      height: calc(100% - 70px);
    }

    .sidebar-items {
      text-align: center;
    }

    .sidebar-item {
      flex-direction: column;
      text-align: center;
      font-size: 10px;
      font-weight: 500;

      .anticon {

        &.active .anticon,
        &:hover .anticon {
          &::before {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }

    .sidebar-wrapper,
    .ant-layout-sider-children,
    .sidebar-upper {
      align-items: center;
    }


    .sidebar-options {
      align-items: center;

      .option-item {
        padding: 0;
        height: 24px;
        width: 24px;
        justify-content: center;
      }

      .text {
        display: none;
      }
    }
  }
}
.drawer {
  position: fixed;
  z-index: 9999;
  display: none;
  visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(48, 53, 124, 0.57);
  overflow: hidden;

  &.active {
    display: block;
    visibility: visible;
  }

  .closing-mask {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .drawer-content {
    position: absolute;
    z-index: 1;
    background: #fff;
    transition: 2s;
  }

  &.top {
    .drawer-content {
      top: 0;
      width: 100%;
    }

    &.active {
      .drawer-content {
        animation: slide-down 0.2s;
      }
    }
  }

  &.bottom {
    .drawer-content {
      bottom: 0;
      width: 100%;
    }

    &.active {
      .drawer-content {
        animation: slide-up 0.2s;
      }
    }
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(200px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-200px);
  }

  100% {
    transform: translateY(0);
  }
}
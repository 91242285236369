@import "styles/theme.scss";

.flex-column {
  display: flex;
  flex-direction: column;
}

.ant-row.tablist-container {
  width: 100%;
  margin: 0 !important;
  position: relative;

  .ant-row {
    margin: 0 !important;
  }
  .ant-col {
    padding: 0 !important;
  }

  & > .ant-modal-mask {
    display: none;
  }
  .tablist-prelist-container {
    height: 100%;
    position: absolute;
    border-right: 1px solid $tab-border;
    backdrop-filter: blur(30px) saturate(2);
    -webkit-backdrop-filter: blur(30px) saturate(2);
    padding-inline: 0;
    padding-block: 13px;
    transition: 0.5s cubic-bezier(0.36, -0.01, 0, 0.77);
    white-space: nowrap;

    &.tablist-prelist-container-collapsed {
      flex-basis: 0;
    }

    .tablist-prelist {
      height: 100%;
      display: flex;
      flex-direction: column;

      .tablist-prelist-input {
        height: 5em;
        padding: 1em;
        border-bottom: 1px solid $tab-border;

        .input-container {
          margin-bottom: 0;
          width: 100%;
          .ant-input-affix-wrapper {
            height: 3em;
            order: 1.02062px solid $tab-border;
            border-radius: 4px;
            &:hover {
              border-color: $primary-color-text;
            }
            .ant-input-suffix {
              cursor: pointer;
            }

            @include rwd(320) {
              padding: 4px 0;
              font-size: 12px;
            }
          }
        }
      }

      .tablist-prelist-content {
        flex-flow: column;
        flex: 1;
        overflow: auto;

        .ant-list {
          .tablist-prelist-load-more {
            display: flex;
            padding: 4px 8px 4px 4px;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            background-color: $tab-dark-background;
            border-color: $tab-dark-background;
            border-radius: 44px;
            color: $label-color-light;
            height: 2em;
            font-weight: 600;
            font-size: $sub-font-size;
            line-height: 140%;

            &:hover {
              border-color: $tab-dark-background;
            }
          }
        }

        .ant-list > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items {
          direction: ltr;
          padding-bottom: 10px;

          .ant-list-item {
            margin: 1em;
            padding: 0.7em;
            background: $tab-background;
            border-radius: 4px;
            border: none;
            cursor: pointer;
            h4 {
              color: $primary-color-dark;
              font-weight: 400;
              margin: 0;
              word-wrap: none;
              word-break: break-word;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              img {
                margin-right: 0.6em;
              }
            }
            .anticon {
              margin: 3px;
            }
            .ant-divider {
              display: none;
            }
          }

          .ant-list-items {
            margin-bottom: 0;
          }

          .ant-list-item {
            &.tablist-prelist-inner {
              height: 1.8em;
              background-color: $label-color-light;
              .ant-list-item-meta-title {
                font-size: 12px;
                color: white;
              }
              .anticon svg {
                fill: white;
                width: 0.75em;
                height: 0.75em;
              }
            }

            &.selected {
              background: white;
              border: 1px solid $primary-color-dark;
              box-sizing: border-box;
              border-radius: 4px;
              .ant-divider {
                display: block;
                margin: 0px 5px;
                height: 1.2em;
              }
            }
            &.disabled {
              display: none;
            }
          }
        }
      }
    }
    // }
  }

  .input-container {
    width: auto;
  }

  .tablist-content {
    @include custom-scrollbar;
    flex-wrap: wrap;
    overflow: auto;
    backdrop-filter: blur(30px) saturate(2);
    -webkit-backdrop-filter: blur(30px) saturate(2);
    padding-inline: 0;
    transition: 0.5s cubic-bezier(0.36, -0.01, 0, 0.77);

    &.tablist-prelist-container-collapsed {
      width: 100% !important;
    }

    .price-content {
      padding-bottom: 65px;
    }

    .ant-tabs-tabpane {
      display: block;
      width: 100%;
      overflow-y: auto;
      .tablist-active-tab {
        min-width: 100%;
        // width of price table in modal when there's custom column
        &.tablist-custom {
          min-width: calc(1000px + var(--custom-columns) * 150px);
        }
      }
    }
    .input-suffix-text {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0;
    }

    .price-options {
      display: flex;
      padding: 0.5em 0.25em;
      // justify-content: flex-end;

      .ant-divider {
        display: none;
      }

      .select-container {
        width: calc(100% - 30px);

        .price-options-dropdown {
          width: 100%;
        }
        .ant-select-selector {
          height: 35px !important;
          width: 100%;
          border-radius: 4px;
          .ant-select-selection-item {
            padding: 3px 13px 3px 0;
          }
        }
      }
      .tablist-input-options {
        cursor: pointer;
        font-size: 27px;
        transform: translate(5px, 2px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
      }
      .edit-icon {
        height: 24px;
        min-width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        &:hover {
          background: $primary-color-01-light;
        }
        > svg {
          height: 14px;
          width: 14px;
        }
      }
    }

    .tablist-inactive-tab {
      display: none !important;
    }

    .ant-tabs-nav-disable {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;

      .ant-tabs-nav {
        display: none;
      }

      .ant-tabs {
        flex: 1;
      }

      .ant-tabs-content-holder {
        display: flex;
        flex-direction: column;
        .ant-tabs-content {
          flex: 1;
        }
      }
    }

    // check dynamic columns
    .price-inputs {
      flex: 0 0 300px;
    }
    .tablist-quantity {
      .price-inputs {
        flex: 0 0 420px;
      }
    }
    .tablist-recurring {
      .price-inputs {
        flex: 0 0 540px;
      }
    }

    // for value view
    .price-inputs.inputs-value {
      flex: 0 0 30px;
      &.inputs-quantity {
        flex: 0 0 170px;
      }
    }

    .tablist-sortlist-container {
      display: flex;
      margin: 0 0 0.5em 0em;
      justify-content: center;

      &:first-child {
        margin-top: 1em;
      }

      .tablist-sortlist-content {
        width: 100%;
        border: 1px solid $tab-border;
        box-sizing: border-box;
        border-radius: 4px;
        background: white;
        margin: 0 1em 0 0;
      }

      .handle {
        flex-direction: column;
        justify-content: center;
        display: flex;
        width: 15px;
        font-size: 30px;
        cursor: move;
        cursor: -webkit-grabbing;
        .anticon {
          padding-bottom: 2px;
        }
      }
    }

    // for value view
    .price-inputs.inputs-value {
      flex: 0 0 30px;
      &.inputs-quantity {
        flex: 0 0 170px;
        &.inputs-recurring {
          flex: 0 0 290px;
        }
      }
    }

    .tablist-input-container {
      display: flex;
      align-items: flex-start;
      .deliverable {
        display: flex;
        flex: 1 !important;
      }

      .tablist-input.super-textarea {
        flex-direction: column;
        margin-bottom: 6px;
        width: calc(100% - 45px);
        label {
          padding-left: 1em;
          padding-top: 0.5em;
          font-size: 10px;
          line-height: 140%;
          display: flex;
          align-items: center;
          color: $label-color-light;
        }
        textarea {
          color: $primary-color-dark;
          min-height: 36px;
          height: 36px;
          &:focus {
            border-right-width: 0;
            box-shadow: none;
          }
        }
      }

      .ant-checkbox-wrapper {
        margin: 1em 0 0 0.5em;
        &.ant-checkbox-wrapper-disabled {
          .ant-checkbox-disabled {
            .ant-checkbox-inner::after {
              border-color: white;
            }
          }
          .ant-checkbox-disabled:not(.ant-checkbox-checked) {
            .ant-checkbox-inner {
              &:before,
              &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 2px;
                height: 18px;
                background-color: lightgrey;
                transform: rotate(45deg) translate(-50%, -50%);
                transform-origin: top left;
                content: "";
                opacity: 1;
                border-color: lightgrey;
              }
              &::after {
                transform: rotate(-45deg) translate(-50%, -50%);
              }
            }
          }
        }
      }

      .tablist-input {
        display: flex;
        margin-bottom: 0;
        textarea,
        input {
          @include custom-scrollbar;
          border-style: none;
          border-color: Transparent;
          overflow: auto;
          height: 35px;
          color: $primary-color-dark;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:focus {
            outline: none;
            box-shadow: none;
            border-right-width: 0;
            border-color: $tab-border;
          }
          &:hover {
            border-color: $tab-border;
          }
        }
      }

      .item-name-wrap {
        display: flex;
        .tablist-input {
          margin-top: 0.5em;
          width: 100%;
        }
      }

      .ant-col {
        .ant-row {
          flex-flow: row;
          .tablist-price {
            input {
              appearance: textfield;
              -moz-appearance: textfield;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
            .input-suffix-text {
              padding: 0.5em 0.25em;
            }
          }
        }
      }

      .tablist-price {
        .input-suffix-text {
          padding: 0 0.25em;
          line-height: 35px;
        }
      }

      .inputs-table {
        display: flex;
        justify-content: flex-end;
      }

      .price-inputs {
        display: flex;
        justify-content: flex-end;

        .ant-col {
          &:first-child {
            flex-direction: column;
            width: 60%;
            .ant-row:first-child {
              justify-content: flex-end;
            }
          }
        }

        .quantity input,
        .items input {
          padding: 4px 5px;
          &::placeholder {
            font-size: 0.9em;
          }
        }

        &.ant-col.inputs-table {
          .custom,
          .tablist-quantity,
          .tablist-items,
          .price {
            padding-right: 10px;
          }
        }
      }

      .custom,
      .price,
      .miles-time,
      .tablist-quantity,
      .tablist-items {
        padding: 0.5em 0.25em;
        input {
          border: 1px solid $tab-border;
          box-sizing: border-box;
          border-radius: 4px;
          height: 2.5em;
          // width: 90px;
        }
        .input-container.input-number-container {
          margin-bottom: 0;
        }
        .input-suffix-text {
          width: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 0;
        }
      }
      .price {
        display: flex;
        .currency {
          padding: 7px 2px 0 0;
        }
      }

      .miles-time {
        input {
          width: 100%;
          min-width: 100px;
        }
      }

      .miles-input {
        width: 60%;
        margin: 0.5em 0;
      }
      .miles-time {
        width: 40%;
      }

      .tablist-select-dropdown {
        display: flex;
        justify-content: space-between;
        width: 40%;
        .select-container {
          width: calc(100% - 40px);
        }
        .ant-select-selector {
          height: 35px !important;
        }
      }

      .input-prefix-text {
        transform: unset;
        position: relative;
        padding: 0.1em;

        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 12px;
        margin-bottom: 0;
      }

      .tablist-discount {
        display: flex;
        padding: 0.5em 0 0;
        position: relative;
        margin-bottom: 0;
        &.input-container {
          direction: unset !important;
        }

        input {
          border: 1px solid $tab-border;
          height: 2.5em;
          margin-left: auto;
        }

        .input-suffix-text {
          padding: 0.8em 0.5em;
          width: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 0;
          position: absolute;
          right: 0;
          transform: translateX(100%);
          line-height: 12px;
          span {
            padding-right: 5px;
            color: $tab-border;
            cursor: pointer;
            &.suffix-active {
              color: $primary-color-text;
            }
          }
        }

        .hidden-currency {
          opacity: 0;
          padding: 0 0.25 !important;
          visibility: hidden;
        }
        .input-discount-delete {
          position: absolute;
          right: 4px;
          z-index: 9;
        }
      }

      .input-discount-delete {
        height: 32px;
        width: 32px;
        position: absolute;
        bottom: 8px;
        right: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .tablist-select-dropdown {
        display: flex;
        justify-content: space-between;
        width: 40%;
        .select-container {
          width: calc(100% - 40px);
        }
        .ant-select-selector {
          height: 35px !important;
        }
      }

      .price-options-dropdown {
        width: 100%;
        .ant-select-selector {
          height: 35px !important;
          width: 100%;
          border-radius: 4px;
        }
      }

      .tablist-input-options {
        cursor: pointer;
        font-size: 27px;
        transform: translate(1px, 2px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        padding: 5px;
      }
    }

    .more-icon {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
    }
    .super-sortlist {
      .anticon-more {
        @extend .more-icon;
        margin-top: 9px;
      }
    }
    .miles-sortlist {
      .anticon-more {
        @extend .more-icon;
        margin: 4px 0 0 -7px;
      }
    }

    .tablist-input-header {
      display: flex;
      margin: 0em 1em 1em 1em !important;
      background: $background-color;
      border-radius: 4px;
      padding: 4px 0;

      .custom {
        display: flex;
        align-items: center;

        .input-container {
          .ant-input-affix-wrapper {
            background-color: inherit;
            padding: 0 5px;
            border: none;
            transition: unset;
            -webkit-transition: unset;
            border: 1px solid transparent;

            &:hover,
            &:focus-within,
            &:focus {
              border: 1px solid $primary-color-01-light;
              border-radius: 4px;
              box-shadow: none;

              .ant-input-suffix {
                display: flex;
              }
            }

            &:focus-within,
            &:focus {
              background-color: white;
              .ant-input-suffix {
                display: none;
              }
            }

            .ant-input-suffix {
              height: 18px;
              width: 18px;
              margin: auto;
              display: none;
              border-radius: 2px;
              background-color: $primary-color-01-light;
              cursor: pointer;

              .anticon {
                margin: auto;
                svg {
                  fill: $primary-color;
                }
              }
            }
          }

          input {
            height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background-color: inherit;

            &:focus {
              background-color: white;
              padding: 4px 4px 4px 4px !important;
            }
          }
        }
      }

      .ant-col:last-child {
        display: flex;
        justify-content: flex-end;
      }

      .tablist-label {
        padding: 0 0.25em !important;
        color: $primary-color-dark;
        display: flex;
        align-items: center;

        &.deliverable {
          display: flex;
          justify-content: space-between;
          min-width: 200px;
          flex: 1 !important;

          .tablist-add-deliverable {
            display: flex;
            align-items: center;
            .anticon {
              cursor: pointer;
              margin-left: 8px;
              &:hover {
                border-radius: 2px;
                background-color: $primary-color-01-light;
              }
            }
          }
        }

        .ant-divider {
          height: 1.2em;
          margin: 0 0 0 8px;
        }

        .tablist-quantity-label {
          display: flex;
          align-items: center;
          width: 150px;
        }

        @media screen and (max-width: 916px) {
          &.deliverable {
            margin-bottom: 0.5em;
          }
        }
      }

      .deliverable {
        padding-right: 10px !important;
        padding-left: 10px !important;
        display: flex;
        align-items: center;
      }

      .tablist-input-label {
        margin: auto 0;
        padding: 0 1em !important;
        color: $primary-color-text;
      }

      &:not(.structured) .deliverable {
        padding-left: 10px !important;
      }

      .input-container {
        margin: unset;
      }
      .borderless-input {
        background-color: transparent;
        border: unset;
        box-shadow: unset;
        resize: none;
        padding: 4px 4px 4px 0 !important;
      }
      &.structured {
        .borderless-input:not(.flush) {
          padding: 4px 6px !important;
          border: 1px solid $primary-color-light;
        }
      }

      .input-container {
        margin: unset;
      }
      .borderless-input {
        background-color: transparent;
        border: unset;
        box-shadow: unset;
        resize: none;
        padding: 4px 4px 4px 0 !important;
      }
      &.structured {
        .borderless-input:not(.flush) {
          border: 1px solid $primary-color-light;
          padding: 4px 6px !important;
        }
      }
    }

    .tablist-sortlist-container .tablist-sortlist-content .tablist-input-description.input-container {
      margin: 0 0.72em 0.72em;
      width: auto !important;
      background: #ffffff;
      border: 1.02062px solid $tab-border;
      border-radius: 4.08247px;

      textarea {
        @include custom-scrollbar;
        min-height: 38px;
        border: 0;
        color: $primary-color-dark;
        line-height: 140%;
        padding: 0.72em;

        &:focus {
          outline: none;
          box-shadow: none;
          border-right-width: 0;
          border-color: $tab-border;
        }
        &:hover {
          border-color: $tab-border;
        }
      }

      &:focus {
        outline: none;
      }
    }

    .ant-empty {
      width: 80%;
      max-width: 300px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .ant-empty-image svg {
        max-width: 100px;
      }
      .ant-empty-description {
        color: $primary-color-text;
        max-width: 350px;
        margin: auto;
      }
    }

    .sidebar-btn {
      margin: 40px 0;
      @extend .flex-center;
    }

    // for empty list in price, milestone show the add button below empty icon
    .ant-empty ~ {
      .sidebar-btn {
        position: absolute;
        top: calc(50% + 30px);
        left: 50%;
        transform: translateX(-50%);
        &.super {
          top: calc(50% + 60px);
        }
      }
    }

    .price-content {
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      // for price edit modal (pricing.strategy = table)
      .tablist-active-tab {
        // &.tablist-not-empty {
          // min-width: 700px;
        // }

        &.tablist-empty {
          display: flex;
          flex-direction: column;
          flex: 1;
          .tablist-input-header {
            display: none;
          }
        }

        & ~ .sidebar-btn {
          margin: 20px 0 40px;
        }
      }

      // for price edit modal (pricing.strategy = value)
      .sortlist-container {
        .ant-select-selection-search {
          height: 32px !important;
        }
      }
    }

    .sortlist-options-container {
      display: flex;
      justify-content: space-between;
      padding: 1em 1em 1em 1.2em !important;

      .sortlist-options-sidebar {
        padding: 5px;
        border: 1px solid $border-color-base;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          border-color: #40a9ff !important;
        }
      }

      .ant-col {
        &:first-child {
          display: flex;
          gap: 10px;
          .ant-select {
            width: 100px;
            height: 32px;
            @include sm() {
              width: 80px !important;
            }
          }
        }
      }

      button {
        color: $label-color-light;
        max-width: 15em;
        min-width: 5em;

        &.ant-btn-round {
          padding: 4px 12px;
          display: flex;
          justify-content: space-evenly;
          span.anticon {
            padding: 4px 0;
          }
        }
        &.ant-btn-round > .anticon + span,
        &.ant-btn-round > span + .anticon {
          margin-left: 5px;
        }
      }

      .button-inactive {
        background: $background-color;
      }

      .button-disabled {
        background-color: $input-disabled-color;
        cursor: not-allowed;
        border-color: $border-color-base !important;
      }

      .ant-radio-group {
        padding: 2px;
        background: $tab-background;
        border-radius: 4px;
        .ant-radio-button-wrapper {
          border-right-color: $tab-background;
          border-color: $tab-background;
          background: $tab-background;
          color: $primary-color;
          border-radius: 4px;
          font-size: 11px;
          font-weight: 600;
          &:focus,
          &:focus-within {
            outline: none;
            box-shadow: none;
            color: $primary-color-dark;
          }
          &::before {
            background-color: white;
          }
        }
        .ant-radio-button-wrapper-checked {
          border-right-color: $tab-background;
          border-color: $tab-background;
          background: white;
          color: $label-color-light;
        }
      }

      .ant-select-single {
        .ant-select-selection-item {
          color: $primary-color;
        }
      }

      .currency-select {
        .ant-select-selector {
          width: 100% !important;
          padding: 0;
          height: 32px !important;
          border-radius: 6px;
        }
        .ant-select-selection-item {
          padding-left: 6px !important;
          line-height: 32px;
        }
        .ant-select-selection-search {
          width: 100%;
          left: 0;
          .ant-select-selection-item,
          input {
            padding: 0 17px 0 6px !important;
          }
        }
        .ant-select-arrow {
          position: absolute;
          right: 6px;
          top: calc(50% - 0) !important;
        }
      }

      .ant-divider-vertical.pricing {
        display: none;
      }
    }

    .ant-tabs-nav-enable {
      .ant-tabs-top {
        margin-top: 1em;
        padding: 1em 0.4em 1em 1.2em !important;

        .ant-tabs-nav {
          margin: 0;
          .ant-tabs-nav-wrap {
            background-color: white;
            .ant-tabs-nav-list {
              .ant-tabs-tab {
                background-color: white;
                border: none;
                opacity: 0.8;
                .ant-tabs-tab-btn {
                  font-size: $label-font-size;
                  font-weight: 600;
                  letter-spacing: 0.04em;
                  align-items: center;
                  color: $label-color-light;
                }
              }
              .ant-tabs-tab-active {
                border: none;
                background-color: #f8f8f8;
                opacity: 1;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                .ant-tabs-tab-btn {
                  color: $primary-color-dark;
                }
              }
              .ant-tabs-tab:after {
                content: "";
                height: 45%;
                margin: 12px 0px;
                width: 1px;
                position: absolute;
                right: 0;
                top: 0;
                background-color: $tab-border;
              }
              .ant-tabs-tab-active::after {
                height: 0;
              }
              .ant-tabs-nav-add {
                background: white;
                border: none;
                .anticon {
                  border: none;
                  padding: 0.2em;
                  background: $tab-background;
                }
              }
            }
          }
        }

        .ant-tabs-content-holder {
          background: #f8f8f8;
          border-radius: 4px;
          .ant-tabs-content > .ant-tabs-tabpane {
            .tablist-sortlist-container > .tablist-sortlist-content {
              background-color: white;
              .tablist-input {
                input {
                  color: $primary-color-dark;
                }
              }
            }
          }
        }
      }
    }

    .sortlist-container {
      // position: relative;
      .sortlist-price-tab-options {
        display: flex;
        justify-content: space-between;
        padding: 1.4em 1em 0.2em;
        gap: 10px;
      }

      .sortlist-price-delete {
        width: 32px;
      }

      .sortlist-price-fields {
        display: flex;
        gap: 12px;
        flex-wrap: nowrap;
        .input-container {
          margin: 0;
          input {
            color: $primary-color-dark;
            height: 32px;
          }
        }
        .select-container {
          .ant-select {
            .ant-select-selector {
              border-radius: 4px;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1200px) {
      @include custom-scrollbar;
    }

    .totals-popover-container {
      position: sticky;
      justify-content: flex-end;
      display: flex;
      bottom: 0;
      float: right;
      z-index: 1;
      padding-bottom: 8px;
      .totals-container-tag {
        background: #ffffff;
        box-shadow: 0px 0px 15px -2px rgba(3, 20, 63, 0.2);
        border-radius: 68px;
        min-width: 18em;
        max-width: 30em;
        height: 4.5em;
        padding: 1em;
        display: flex;

        .anticon {
          padding: 0em 1em;
        }
        .totals-container-tag-info {
          display: flex;
          p {
            padding: 0.3em 1em;
            font-size: $label-font-size;
            line-height: 1;
            display: flex;
            align-items: center;
            color: $label-color-light;
            margin: 0;
          }
          .totals-container-tag-price {
            color: $primary-color-dark;
            font-size: $medium-text-font-size;
          }
          &:before {
            content: "";
            height: 50%;
            margin: 1em 0px;
            width: 0.1em;
            position: absolute;
            top: 0;
            background-color: $tab-border;
          }
        }
      }
    }
  }

  // when "price" & "payment-type" is visible
  .tablist-active-tab {
    .custom,
    .quantity,
    .items {
      width: 150px;
    }
    .price,
    .price-options {
      width: 180px;
    }
    &.tablist-quantity,
    &.tablist-recurring {
      .price,
      .price-options {
        width: 150px;
      }
    }

    // hide empty placeholder when duration column is not visible
    &:not(.tablist-recurring) {
      .quantity.empty-placeholder {
        display: none !important;
      }
    }
  }

  @media (max-width: 1199.9px) {
    .tablist-prelist-container {
      transform: translateY(100px);
      opacity: 0;
      bottom: 0;
      width: 100%;
      height: calc(100% - 80px);
      position: fixed;
      visibility: hidden;
    }

    &.active {
      .ant-modal-mask {
        display: block;
      }

      .tablist-prelist-container {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        transition: 0.2s;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1199.9px) {
    .tablist-prelist-container {
      transform: translateX(-100px);
      width: 300px;
      height: 100%;
    }

    &.active {
      .tablist-prelist-container {
        transform: translateX(0);
      }
    }
  }

  @media (min-width: 992px) {
    .tabList-drawer {
      position: absolute;
    }

    .tabList-drawer .ant-modal-mask {
      opacity: 0.1;
    }

    .content {
      border-radius: 0;
      width: 260px;
      height: 100%;
    }
  }
  @include rwd-min(1200) {
    .tablist-prelist-container {
      position: absolute;
      top: 0;
      height: 100%;
      border-right: 1px solid $tab-border;
      width: 80%;
      max-width: 300px;
    }

    .tablist-content {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: calc(100% - 85px);
      overflow: auto;
      display: flex;
      flex-direction: column;
    }

    .tablist-prelist-container {
      height: 100%;
      width: 25%;
      max-width: 25%;
      // z-index: 1;
    }

    .tablist-content {
      width: 75% !important;
      height: 100%;
    }
  }
}

.drawer-popover {
  position: absolute;
  right: auto;
  left: -10px;
}

.tablist-options-dropdown-menu {
  width: 16em;
  padding: 10px 0;
  .ant-dropdown-menu-item {
    padding: 8px 12px;
    color: $primary-color-text;
    display: flex;
    align-items: center;
  }
}

.wizard-content-container {
  .ant-row.tablist-container {
    .tablist-content {
      .ant-tabs-nav-disable {
        .ant-tabs {
          .tablist-sortlist-container {
            .handle {
              margin-top: 3px;
            }
          }
        }
      }
    }
  }
}

.ant-modal-body {
  .ant-row.tablist-container {
    .tablist-content {
      .ant-tabs-nav-disable {
        .ant-tabs {
          .tablist-sortlist-container {
            .handle {
              margin-top: 3px;
            }
          }
        }
      }
    }
  }
}

/*--currency dropdown in tablist options--*/
.tablist-currency-menu {
  background-color: #fff;
  .ant-select-item {
    padding: 6px !important;
  }
  .ant-divider-horizontal {
    margin: 6px 0 !important;
  }
  .ant-select-item-empty {
    padding: 0;
  }
  .add-button,
  .custom-button {
    padding: 0;
    border: unset;
    font-size: 0.9em;
    width: 100%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      color: $primary-color;
    }
  }
  .input-wrap {
    display: flex;
    position: relative;
    input {
      padding-right: 32px;
      padding-left: 6px;
      &::placeholder {
        font-size: 12px;
      }
    }
  }
  .ant-select-item {
    .custom-currency-suffix {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 30px;
      background: #fff;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .ant-spin {
        height: 14px;
      }
    }
    &:hover {
      .custom-currency-suffix {
        display: flex;
      }
    }
  }
  .add-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    background-color: rgba(0, 0, 0, 0.05);
    border-left: 1px solid $border-color-base !important;
    z-index: 2;
  }
}

textarea {
  cursor: auto;
  padding-right: 22px !important;
}

.wizard-body {
  // dont display tablist sidebar opener button for wizard, will show only for modal
  .tablist-content .sidebar-btn {
    display: none !important;
  }
}

.wizard-language-rtl,
.rich-editor-price-edit-hebrew {
  .price-inputs {
    .price {
      direction: rtl;
      .currency {
        padding: 7px 0 0 2px !important;
      }
    }
    &.ant-col.inputs-table {
      .custom,
      .tablist-quantity,
      .tablist-items,
      .price,
      .price-options {
        padding-left: 1.5em;
        padding-right: 0em !important;
      }
    }
  }
  .tablist-discount {
    .input-prefix-text {
      display: none !important;
    }
  }

  .ant-input-clear-icon {
    left: 5px;
    right: unset !important;
  }
  textarea {
    padding-right: 0.72em !important;
    padding-left: 22px !important;
  }

  .tablist-input-container {
    .input-container {
      direction: rtl;
    }
  }

  .ant-row.tablist-input-header {
    input,
    textarea {
      direction: rtl;
      padding-left: 8px !important;
    }
    div.tablist-label {
      direction: rtl;
      &.deliverable {
        padding-right: 0 !important;
      }
      &.price-options {
        .ant-divider {
          display: block;
        }
      }
    }
  }

  .super-textarea {
    textarea {
      text-align: right;
    }
  }
}

.tablist-input-header {
  .price-options {
    align-items: center;
  }
}

.tabList-drawer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;

  .content {
    background: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1002;
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;

    .drawer-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(5, 5, 5, 0.06);

      h2 {
        font-size: 16px;
        font-weight: 600;
        color: #05034d;
        margin: 0;
      }

      .tabList-drawer-close {
        width: 30px;
      }
    }

    .body {
      padding: 20px;
      overflow: auto;
      flex: 1;

      .tabList-checkboxes {
        display: block;
        text-align: left;

        .tabList-checkbox-item {
          justify-content: flex-start;
          margin-bottom: 1em !important;
          flex-wrap: unset;
          align-items: flex-start;

          label {
            span {
              color: #05034d;
            }
          }

          .checkbox-list-info-icon {
            margin: auto 0.3em;
            cursor: pointer;
            position: relative;
            right: 6px;

            svg {
              height: 16px;
              width: 16px;
              border: 1px solid $svg-fill-border-extra-light;
              border-radius: 50px;

              path {
                fill: $svg-fill-border-light;
              }

              circle {
                fill: #ffffff;
              }
            }
          }
        }
      }
    }

    .footer {
      padding: 10px;
    }
  }

  .ant-input {
    margin-bottom: 20px;
    height: 40px;
    border-radius: 6px;
  }
}

.delivs {
  .overflow-dropdown-list-container {
    display: flex;
    gap: 10px;
    padding: 0;
    // @media (max-width) {

    // }
  }
  @media (max-width: 1199.9px) {
    .sortlist-options-container {
      .ant-radio-button-wrapper {
        padding: 0 6px;
      }
    }
  }
}

.ant-modal-body {
  .delivs {
    @media (max-width: 1199.9px) {
      .ant-empty {
        flex: 1;
        align-items: flex-end !important;
      }
    }

    .sortlist-container {
      .ant-empty {
        display: flex;
        justify-content: center;
        position: relative;
        min-height: 150px !important;
        align-items: flex-end !important;
      }
    }

    .tablist-empty ~ .sidebar-btn {
      position: absolute;
      top: calc(50% + 60px);
      left: 0;
      width: 100%;
    }

    .table {
      .sortlist-options-container {
        padding: 16px 16px 14px !important;
      }
    }
    .ant-tabs-content-holder {
      position: relative;
      @extend .flex-column;
      .ant-tabs-content {
        @include custom-scrollbar;
        overflow-y: auto;
        position: absolute;
        height: 100%;
        width: 100%;

        .ant-tabs-tabpane {
          padding-bottom: 80px !important;
        }
      }
    }
    .value {
      .ant-tabs-nav-list {
        padding-left: 16px;
      }

      .ant-tabs-nav-enable {
        flex: 1;
        @extend .flex-column;
        .ant-tabs-editable {
          flex: 1;
        }
        .ant-tabs-content-top {
          flex: 1;
        }
      }
      .price-content {
        padding-bottom: 20px !important;
      }
      .ant-tabs-editable-card {
        padding: 16px 0 0;
      }
    }
  }
}

.wizard-body {
  .delivs {
    .ant-tabs-content-holder,
    .ant-tabs-nav-enable {
      flex: 1;
      @extend .flex-column;
    }
    .ant-tabs {
      flex: 1;
    }

    .ant-tabs-content,
    .sortlist-container {
      flex: 1;
    }

    .value {
      .sortlist-container {
        min-height: 250px;
        flex: 1;
      }
      .tablist-empty {
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.tabList-drawer-right > .tabList-drawer-content-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 260px;
  max-width: 100vw;
  transition: all 0.3s;
  box-shadow:
    -6px 0 16px 0 rgba(0, 0, 0, 0.08),
    -3px 0 6px -4px rgba(0, 0, 0, 0.12),
    -9px 0 28px 8px rgba(0, 0, 0, 0.05);
}

.title-edit {
  .ant-input {
    margin-bottom: 20px;
  }
}

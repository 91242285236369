@import "styles/theme.scss";

.content-table-side-menu {
  background-color: #f5f6fa!important;

  .menu-item-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 30px);
    display: inline-block;
  }

  .ant-menu-item{
    &.editable:hover .menu-item-text {
      max-width: calc(100% - 60px);
    }
  }

  .toc-closing-mask{
    background-color: $loader-overlay-background;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  &.ant-layout-sider-is-not-collapsed {
    z-index: 1001!important;
  }

  &.ant-layout-sider {
    z-index: 14;
    position: fixed;
    flex: unset !important;
    max-width: unset !important;
    min-width: unset !important;
    width: unset !important;

    .content-table-menu {
      background-color: #fff;
      position: fixed;
      top: 65px;
      z-index: 14;
      max-width: 250px !important;
      width: 250px !important;
      height: calc(100% - 65px);
      transform: translateX(0);
      transition: 0.2s;
      display: flex;
      flex-direction: column;
      border-left: 1px solid $border-color-tab;
      border-right: 1px solid $border-color-tab;
      @media (min-width:1200px) {
        height: auto;
        max-height: calc(100% - 65px);
      }
    }

    &.wix-content-table-side-menu {
      top: 0;
    }

    .content-table-button {
      background-color: $header-background-color;
      color: $primary-color-text;
      height: 48px;
      width: 64px;
      border-right: 1px solid $border-color-tab;
      border-bottom: 1px solid $border-color-tab;
      cursor: pointer;
      @extend .flex-center;

      svg {
        width: 30px;
      }
    }

    .ant-layout-sider-children {
      .content-table-header {
        cursor: pointer;
        height: 48px;
        background-color: $header-background-color;
        color: $primary-color-text;
        padding: 16px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;

        .anticon {
          @extend .flex-center;
          svg {
            width: 30px;
          }

          &.content-table-icon {
            transform: rotate(180deg);
          }
        }
      }

      .content-table-header.download-icon-header {
        top: 43px;
      }

      .ant-menu {
        flex: 1;
        color: $primary-color-text;
        overflow-y: auto;
        overflow-x: hidden;
        @include custom-scrollbar;

        .ant-menu-item {
          padding-left: 16px !important;

          &:hover {
            color: $primary-color-text;
            background-color: $primary-color-01-light;
          }
        }

        .ant-menu-item-selected {
          background-color: $primary-color-01-light;

          .anticon {
            color: $primary-color-text;
          }
        }

        .ant-menu-item,
        .anticon+span {
          color: $primary-color-text;
          background: transparent;
          direction: ltr;
        }

        li {
          padding-right: 5px !important;
        }
      }
    }

    .ant-menu-item {
      &:hover {
        .eye-icon path {
          fill: #7876c1;
        }
      }
    }

    .eye-icon,
    .edit-icon {
      float: right !important;
      margin-top: 4.5px;
      margin-right: 0;
      padding: 4px 4px;
      vertical-align: middle !important;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;

      &:hover,
      &.content-item-name-active {
        background: $light-color !important;
      }
    }

    .download-icon {
      margin-left: 0.7em;
      margin-right: 0.7em;

      &:hover,
      &.content-item-name-active {
        background: $light-color;
      }
    }

    &.language-rtl {
      .content-table-button {
        border-left: 1px solid $border-color-tab;
      }

      .ant-menu {
        .ant-menu-item {
          padding-right: 16px !important;
          padding-left: 8px !important;
        }
      }

    }
  }

  @media screen and (min-width: 767px) {
    .toc-closing-mask {
      display: none;
    }
  }
}

.editor{
  .content-table-menu {
    border-bottom: 1px solid $border-color-tab;
  }
}

.content-table-collapsed {
  .content-table-menu {
    transform: translateX(-100%) !important;
  }

  @media screen and (min-width: 1500px) {
    .content-table-side-menu {
      height: auto;

      .content-table-menu {
        position: relative;
        top: 0;
        display: none;
      }
    }
  }
}

.content-table-active {
  @media screen and (min-width: 1500px) {
    .content-table-side-menu {
      position: relative;

      flex: 0 0 250px !important;
      max-width: 250px !important;
      min-width: 250px !important;
      width: 250px !important;

      .content-table-menu {
        display: block;
      }
    }
  }
}

.content-table-footer {
  border-top: 1px solid #ccced7;
  height: 48px;
  padding: 1em 1.5em;
  color: #05034d;
  font-weight: 600;
  display: flex;
  cursor: pointer;

  div {
    width: 100%;
    display: flex;

    .link-text {
      color: #05034d;
      font-weight: 600;
    }
  }
}

.publish-body {
  .content-table-side-menu {
    .menu-item-text {
      max-width: 100%!important;
      width: 100%;
    }

    .menu-item-icons {
      display: none;
    }
  }
}

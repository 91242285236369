@import "styles/theme.scss";

.content-library {
  padding-bottom: 0 !important;
  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs {
    margin-top: 1.5em;
  }
  .ant-tabs-content-holder {
    height: calc(100vh - 170px - var(--margin-top));
    display: flex;
    flex-direction: column;

    .ant-tabs-content {
      flex: 1;
      position: relative;
    }
  }

  .ant-tabs-ink-bar-animated {
    display: none;
  }

  .ant-tabs-nav-wrap {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
  }

  .ant-tabs-extra-content {
    flex-grow: 1;
  }

  .tab-icons-cotainer>span {
    margin: 0 5px;
  }

  .anticon-file {
    display: none;
  }

  .ant-tabs-tab {
    color: $primary-color-text;
    text-transform: uppercase;
    font-size: $label-font-size !important;
    height: 33px;
    line-height: 150%;
  }

  .split-d1 {
    padding: 10px;
    padding-right: 0;
    width: 280px;
    z-index: 2;
    // enter folder name modal
    .confirm-modal {
      max-width: calc(100% - 10px);
    }
  }
  .split-d2 {
    position: relative;
    padding: 20px;
    overflow-y: auto;
    width: calc(100% - 280px);
  }

  .content-library-tabs {
    position: relative;

    .tab-menu-opener {
      display: none;
    }

    .ant-tabs-nav-list {
      padding-top: 0.3em;
      gap: 12px;
      justify-content: center;
      align-items: center;
      @include md() {
        gap: 0;
      }
    }

    @include md() {
      .tab-menu-opener {
        display: block;
        position: absolute;
        top: 100px;
        z-index: 2;
        border: unset;
        background: $background-color;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 6px 6px 0;
        transition: .2s;
        left: 0;

        .anticon {
          svg {
            transform: translateX(8px);

            &>path {
              stroke: $primary-color-light;
            }
          }
        }
      }

      .split-d1,
      .split-d2 {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: .2s;
      }
      .split-d1 {
        width:280px;
      }
      &.show-screen-1 {
        .split-d1 {
          transform: translateX(0);
          opacity: 1;
        }

        .tab-menu-opener {
          left: 280px;
          .anticon{
            transform: rotate(180deg);
          }
        }

        .split-d2 {
          transform: translateX(280px);

          &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.285);
            z-index: 2;
            left: 0;
            top: 0;
          }
        }
      }

      &.show-screen-2 {
        .split-d1 {
          transform: translateX(-100%);
          opacity: 0;
        }

        .split-d2 {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }

  .ant-tabs-tab {
    margin-right: 0px;
    padding: 0px 16px;
    font-size: 12px;
    color: $primary-color-text !important;
    line-height: 150%;
    border-radius: 4px;

    &:hover {
      background: rgba(47, 44, 136, 0.03);
    }

    @include xs() {
      padding: 0px 10px;
    }
  }

  .ant-tabs-tab:nth-child(5).ant-tabs-tab-active {
      margin-right: 10px;
  }

  .ant-tabs-tab-active {
    background: $background-color !important;

    .ant-tabs-tab-btn {
      color: $primary-color-text !important;
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold !important;
      line-height: 150%;
    }
  }

  .empty-wrapper {
    display: flex;
    align-content: center;
    justify-items: center;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 300px;
  }


  @include rwd-min(767) {
    .split-d2 {
      border-left: 1px solid #f0f2f5;
      @include custom-scrollbar;
    }
  }

  @include rwd-min(1200) {
    padding: 20px 50px;

    .split-d1 {
      width: 300px;
    }

    .split-d2 {
      width: calc(100% - 300px);
    }
  }
}

.tab-icons-container {
  display: flex;
  align-items: center;
  height: 44px;

  @media screen and (max-width: 768px) {
    .ant-divider{
      display: none;
    }
  }

  .anticon {
    height: 32px;
    width: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    border-radius: 3px !important;

    svg {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &.hoverable:hover,
    &.anticon-active {
      background: rgba(47, 44, 136, 0.03);
    }
  }

  .vertical-divider {
    height: 1.1em;
    border-left: 1px solid #e2e3e8;
  }

  .content-sort-icon {
    cursor: pointer;

    svg {
      width: 2em;
      height: 2em;
    }
  }


  .content-search-container {
    position: relative;

    .ant-input-affix-wrapper {
      border: 0 !important;
      border-radius: 6px;
      transition: 0.3s;
      width: 80px;
      opacity: 0;
      position: absolute;
      visibility: hidden;
      padding-right: 4px;
      top: -4px;
      right: 0;
    }

    &.showInput {
      .ant-input-affix-wrapper {
        width: 200px;
        opacity: 1;
        z-index: 2;
        visibility: visible;
      }
    }
  }



  .filter-icon {
    cursor: pointer;
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 32px;
    background: rgba(226, 227, 232, 0.40);

    .text {
      color: $primary-color-text;
      /* Small Paragraph */
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      /* 19.6px */
    }

    .close-icon {
      border-radius: 50%;

      svg {
        :first-child {
          fill: #CBCED8;
        }
      }
    }
    @media (max-width: 991.9px) {
      background: transparent;
      padding: 0;
      .text,
      .close-icon {
        display: none;
      }
    }
  }

  @media (min-width : 992px) {
    .content-search-container {
      transition: 0.3s;
      width: 32px;
      &.showInput {
        width: 200px;
      }
      .ant-input-affix-wrapper {
        right: unset;
        left: 0;
      }
    }
  }
}



.ant-tree-node-content-wrapper {
  .content-library-tree-title .anticon {
    margin-left: 8px;
    display: none;
    &:nth-last-child(1){
      margin-right: 8px;
    }
  }

  &:hover .content-library-tree-title .anticon {
    display: inline-block;
  }
}

.preview-proposal-modal {
  .ant-modal-content {
    height: calc(100vh - 32px);
    border-radius: 6px;
    overflow: hidden;
  }
  h3 {
    &.title {
      line-height: 25px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      max-width: 400px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.handle-user-lock{
  .content-library {
    .ant-tabs-content-holder{
      height: calc(100vh - 234px);
    }
  }
}
@import 'styles/theme.scss';

.rich-editor-components-wrapper {
  position: relative;
  padding-bottom: 10px;
  padding-top: 20px;
    &:hover:before {
      content: '';
      position: absolute;
      height: 90px;
      width: calc(100% + 20px);
      bottom: -50px;
    }
  &-left {
    margin-right: auto;
  }

  &-center {
    margin: auto;
  }

  &-right {
    margin-left: auto;
  }

  .testimonial-toolbar{
    cursor: default;
  }
  .table-resize-container {
    opacity: 0;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.4s;
    position: absolute;
    bottom: -17px;
    margin: 20px 0;
    cursor: ns-resize;
  }

  .rich-editor-component-table {
    @include thin-custom-scrollbar;
    // overflow-x: auto;

    .table-container {
      background: white;
      width: 100%;

      .table-content {
        display: contents;
      }

      .table-column-action {
        padding: 0em 0.5em 1em;
        position: absolute;
        top: -25px;
      }

      .table-row-action {
        cursor: pointer;
        opacity: 0;
        position: absolute;
        width: 3%;
        border: 0 !important;
        left: -25px;
        top: 50%;
        transform: translateY(-50%);
        padding-right: 1.3em;
      }

      .table-row-drag {
        left: 5px;
        margin-top: 10px;
      }

      .table-row-drag-container {
        width: 2em;
        padding: 5px !important;
      }

      tr:first-child {
        background-color: lightgray;
        font-weight: 600;
      }

      td:not([draggable="true"]) {
        vertical-align: top;
      }

      .grey2 {
        &:first-child {
          .grey1.font-weight {
            font-weight: 600;
          }
        }
        .grey1 {
          border: 0 !important;
          position: relative;

          .table-column-drag {
            transform: rotate(90deg);
            position: absolute;
            top: -10px;
          }
          .content-editable-item {
            height: 100%;
            width: 100%;
            padding: 0.1em 0.2em;
            &:hover,
            &:focus-visible {
              outline: $section-color-hover auto 1px;
            }
          }
        }
      }
    }
  }

  .rich-editor-components-draggable-icon {
    visibility: hidden;
    position: absolute;
    left: -30px;
    top: -5px;
    z-index: 5;
    cursor: move;
    .anticon {
      position: absolute;
    }
  }

  .rich-editor-components-action-buttons {
    position: absolute;
    display: none;
    top: 100%;
    width: max-content;
    height: max-content;
    z-index: 3;
    align-items: center;
    &.active {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    &.testimonial-toolbar {
      bottom: unset;
      top: 24px;
      left: 4px;
    }

    &.image-toolbar {
      min-width:240px;

      .alignment-icon {
        &.anticon-active{
          background-color: #cbcce0;
        }
        rect {
          fill: #05034d !important;
        }
      }

      @media screen and (min-width: 992px) {
        min-width: 440px;
        flex-wrap : unset!important;
      }
    }

    .font-family {
      min-width: 120px;
    }
    .font-size {
      min-width: 60px;
    }
 
    &:hover:before {
      content: '';
      position: absolute;
      height: 70px;
      width: calc(100% + 20px);
      bottom: -20px;
      left: -20px;
    }
    // style the custom select dropdown in action button toolbar
    .custom-select {
      padding: 2px;
      .custom-select-selector {
        padding: 4px;
        height: 22px;
        border: unset;

        .anticon-down {
          svg {
            display: none;
          }
          height: 12px;
          width: 12px;
          position: absolute;
          background-size: cover;
          right: 4px;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.24061 9.18369C5.84375 9.62111 5.15625 9.62111 4.75939 9.18369L2.25374 6.42193C1.67073 5.77933 2.12669 4.75 2.99436 4.75L8.00564 4.75C8.87331 4.75 9.32927 5.77933 8.74625 6.42193L6.24061 9.18369Z' fill='%23005aff'%3E%3C/path%3E%3C/svg%3E");
        }
        &:hover {
          background-color: $light-color;
          color: $section-color;
        }

        &:not(:hover) {
          .anticon-down {
            filter: grayscale(80%);
          }
        }
      }
      .custom-select-dropdown-list {
        max-height: 250px;
      }
      .custom-select-selection-item {
        padding-right: 14px;
      }
      .custom-select-item {
        padding: 2px 8px;
        font-size: 13px;
        border-bottom: unset;
        min-height: auto;
        &.selected {
          background-color: $light-color;
          color: $section-color;
        }
      }
    }


    .rich-editor-components-action-disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    & > .ant-col {
      background-color: $white-color;
      font-size: $label-font-size;
      font-weight: normal;
      line-height: 140%;
      text-align: center;
      color: $primary-color-text;
      display: flex;
      box-shadow: 0px 2px 4px rgba(0, 38, 95, 0.2);
      border-radius: 4px;
      margin: 4px;

      .sketch-picker-boundary {
        position: fixed;
        height: 100%;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
      }
      .sketch-picker {
        position: absolute;
        width: 200px;
        top: -322px;
        left: -50px;
        z-index: 1;
      }

      & > span {
        cursor: pointer;
        padding: 4px 8px;
        display: inline-flex;
        height: 25px;
        align-items: center;
        &.active {
          background-color: $primary-color-01-light;
        }
        &.disabled {
          cursor: not-allowed;
          opacity:0.6;
        }

        .color-circle {
          height: 16px;
          width: 16px;
          margin: 2px 0 0 4px;
          border: 1px solid lightgray;
          border-radius: 50px;
          background: lightgrey;
        }

        &:not(:last-child) {
          border-right: 1px solid #cbced8;
        }
        &:hover {
          background-color: #f5f6fa;
        }
        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          span.delete-icon {
            height: auto;
            padding: 0;
          }
        }

        &.more-options-icon {
          &.isactive {
            transform: rotate(180deg);
          }
          path {
            fill: $primary-color-light !important;
          }
        }

        &.testimonial-count {
          cursor: default;
          padding-right: 2px;
          .label {
            padding-right: 10px;
          }

          &:hover {
            background-color: unset;
          }
          .ant-btn {
            height: 20px;
            padding: 0 8px;
            border: unset;
            color: $primary-color-dark;
            &.active {
              color: $section-color;
            }
            &.active,
            &:hover {
              background-color: $tab-background;
            }
          }
        }
      }

      &:last-child {
        & > span {
          display: inline-flex;
          align-items: center;
          &.anticon {
            // display: inline;
            svg rect {
              fill: $primary-color-text;
            }
            &.anticon-active {
              background: $primary-color-01-light;
              border-radius: 2px;
            }
          }
        }
      }
    }
    .tool-wrapper{
      position:relative;
      .tool{
        display: inline-flex;
        & * {
          user-select: none;
        }
      }
      .sketch-picker {
        position: absolute;
        top: 25px;
        left: calc(50% - 100px);
      }
    }
  }

  .rich-editor-components-dragger {
    border-top: 2px solid $section-color;
    margin: -4px -14px;
    position: absolute;
    left: 5%;
  }

  .table-container,
  .form-container,
  .gallery-container,
  .image-component-container,
  .pricing-milestone-container,
  .media-component,
  .html-container {
    position: relative;
    z-index: 4;
  }

  .table-container {
    border: 1px solid #dbdbdb !important;
    .grey2 {
      border: 1px solid #dbdbdb
    }
  }

  .pricing-milestone-container.milestones {
    table {
      width: 100% !important;
    }
  }

  .pricing-milestone-container.milestones {
    table {
      width: 100% !important;
    }
  }

  &:hover,
  .isDraggingTable {
    .table-container,
    .form-container,
    .gallery-container,
    .image-component-container,
    .pricing-milestone-container,
    .media-component,
    .html-container {
      box-shadow: 0px 0px 0px 2px #005aff;
      .react-resizable-handle {
        display: block;
      }
    }
    .table-resize-container {
      opacity: 1;
    }
    .rich-editor-components-draggable-icon {
      visibility: visible;
    }
    .rich-editor-components-action-buttons {
      font-family: var(--font-family);
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .form-container {
      .handle {
        visibility: visible;
      }
    }
  }

  &.show-save {
    .rich-editor-components-action-buttons {
      display: flex;
    }
  }
}

.v3-proposalblock-image .rich-editor-components-action-buttons {
  top: calc(100% - 20px);
}

// prevent action button from going outside the editor
.simple-section-content {
  // for last column align action buttons to right
  .ant-col:nth-last-child(1) {
    .rich-editor-components-action-buttons {
      left: unset;
      transform: unset;
      right: 0;
    }
  }
}

.Prosprich-editor-components-popover {
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(4, 3, 49, 0.15);
    border-radius: 4px;
    width: 18em;
    .ant-popover-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: $primary-color-text;
      padding: 0px 0px 12px 0px;
      border: 0;
    }
    .ant-popover-inner-content {
      .ant-col {
        .ant-divider {
          border: 1px solid $secondary-color;
          margin: 8px auto;
          width: 15%;
          min-width: 5%;
        }
        .ant-row {
          display: flex;
          justify-content: space-between;
          padding: 20px 0px 0px;
        }
      }
    }
    .table-delete-popover {
      padding: 20px;
    }
  }
  .button-wrapper {
    button {
      min-width: 100px;
    }
  }
}

.column-action-container {
  display: flex;
  justify-content: center;
}

.component-tool {
  padding: 0!important;
  .tool-button{
    padding: 4px 8px;
  }
  .tool-wrapper {
    position: absolute !important;
    z-index: 100;

    &.shadow-picker-wrapper,
    &.border-picker-wrapper,
    &.delete-picker-wrapper {
      top: 30px;
      padding: 0;
      left: 0;
      text-align: left;
    }
  }
}

// scss for import proposal pricing table
#imported {
  figure.RichEditor-atomic .rich-editor-components-wrapper,
  .image-publish-container {
    .nobreak-secondary.checkbox-enabled {
      padding-left: 2.5em;
    }
  }
}

.language-rtl {
  #imported {
    figure.RichEditor-atomic .rich-editor-components-wrapper,
    .image-publish-container {
      .nobreak-secondary.checkbox-enabled {
        padding-right: 2.5em;
        padding-left: 0em;
      }
    }
  }
}

.shadow-picker,
.border-picker {
  padding: 10px 20px 20px;
}

.rich-editor-toolbar {
  .increment-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;

    span {
      border-radius: 2px;
      width: 12px;
      height: 12px;
      cursor: pointer;
      @extend .flex-center;
      svg { 
        width: inherit;
        height: inherit;
      }

      &:hover{
        background-color: $primary-color-01-light;
      }
    }
  }
}

@import "styles/theme.scss";


.library-modal,
.gallery-modal,
.attachment-modal {
  .ant-modal-header {
    border-bottom: none;
    position: relative;
    padding: 12px 20px 0;
    height: 56px;

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: $primary-color-text;
      display: flex;
      align-items: center;
      gap: 16px;
      margin: 0;
    }

    .ant-divider {
      background: $secondary-color;
      width: 32px;
      height: 1px;
      margin: 0;
      min-width: 0;
      position: absolute;
      bottom: -1px;
    }

    .grey-btn {
      min-width: 50px;
    }
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    flex: 1;
    height: unset;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;

    .secondary-btn {
      min-width: 150px;
    }
  }

  @media (max-width:768px) {
    .ant-modal {
      max-width: 100% !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
    }

    .ant-modal-content {
      height: 100vh;
    }
  }
}

.gallery-modal.select-box,
.attachment-modal.select-box {
  .ant-modal {
    top: 0;
    padding: 0;
  }

  .ant-modal-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .select-file {
    max-width: 500px;
  }

  .choose-from {
    margin: auto;
  }

  @media (min-width:992px) {
    .ant-modal-body {
      padding: 40px;
    }
  }
}


.gallery-modal {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        .gallery-drop-image-container {
          display: flex;
          justify-content: space-between;

          .gallery-images-container {
            border: 1px solid lightgray;
            width: 563px;
            display: flex;
            justify-content: center;
          }

          .dropzone {
            min-height: 50px;
            width: 563px;
            cursor: pointer;
            margin: 0 auto;
            padding-top: 0;
            transform: scale(1.03);
            border-radius: 2px;
            transform-origin: center;
            display: flex !important;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            display: block;
            margin-right: 0px;
            margin-left: 0px;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.58);
            opacity: 1;
            transition: opacity 350ms ease;
            text-align: center;

            p {
              color: white;
              text-align: center;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
}

/*--required only for old gallery modal--*/
.edit-gallery {
  --modal-gap: 0;

  .ant-modal {
    width: calc(100% - 2 * var(--modal-gap)) !important;
    max-width: 1200px;
    top: var(--modal-gap);
    padding: 0;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 0px);
  }

  @media (max-width: 992px) {
    .ant-modal {
      height: 100%;
    }
  }

  @media (min-width: 992px) {
    --modal-gap: 25px;

    .ant-modal-content {
      height: calc(100vh - var(--modal-gap) - var(--modal-gap));
    }
  }
}